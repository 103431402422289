<template>
<div class="container mx-auto ">
<h1 class="font-bold text-xl mt-4 mb-2">Terms of Service</h1>

Last modified 13 September 2023

<h2 class="font-semibold text-lg mt-4">TLDR;</h2>
<ul>
    <li>Do not cheat</li>
    <li>Be a nice person</li>
    <li>Don't do anything bad or break any laws</li>
</ul>


<h2 class="font-semibold text-lg mt-4">Use of OpenGammon</h2>
You can use OpenGammon for its intended purpose, playing backgammon (and its variants)
against other people. You can use all parts of the website that are available to
you, and we are free to restrict access to certain parts of the site.

<h2 class="font-semibold text-lg mt-4">Creating an Account</h2>
For some features you will need an account. Our goal is to minimize the amount of
personal data that we collect (from you and others). However, to run this service
we need to have some personal data (mostly your email address).

To allow for a competitive platform accounts on OpenGammon are considered personal. 
You are not allowed to create multiple accounts or share accounts. You are also 
not allowed to impersonate other people and we reserve the rights to ask for 
identification in case we suspect impersonation.

<h2 class="font-semibold text-lg mt-4">Playing on OpenGammon</h2>
We expect everyone to play to the best of their ability. In case of cheating in any
form we reserve the rights to close the account. 

</div>
</template>
