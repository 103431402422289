<script setup>

import {nextTick, ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Slider from '@vueform/slider'

import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { UPNG } from '../assets/js/UPNG.js'
import { MatFileParser } from '../assets/js/matfile_parser.js'
import GIFEncoder from 'gif-encoder-2'

import { Match } from '@/assets/js/match.js'

const route = useRoute();

import Header from './Header.vue'
import Board from './Board.vue'

const app_server = import.meta.env.VITE_APP_SERVER;

const emit = defineEmits([
    'match-joined',
    'cancel',
]);
const props = defineProps(
);

const test_states = [
    "11ccccchhhjjjjj:66666888dddddoo:N0N:42:W:IB:0:0:1:0",
    "11ccccchhhjjjjj:44666688dddddoo:N0N:41:B:R:0:0:1:1",
    "11ccccghhhjjjjk:44666688dddddoo:N0N:23:W:R:0:0:1:2",
    "11ccccghhhjjjjk:44666688dddddlm:N0N:36:B:R:0:0:1:3",
    "11cccghhijjjjkk:44666688dddddlm:N0N:15:W:R:0:0:1:4",
];

const test_matfile = `
; [Site "OpenGammon"]
; [Match ID "QyzSCVBSyV4"]
; [Player 1 "Deinonychus"]
; [Player 2 "Eran"]
; [Variation "Backgammon"]
; [Crawford "On"]
; [Jacoby "Off"]
; [Beaver "Off"]
; [Cubelimit "64"]

5 point match

 Game 1
 Deinonychus: 0                           Eran: 0                                 
  1) 26: 24/18 13/11                          51: 8/7 13/8                            
  2) 44: 25/21 8/4 8/4 13/9                   52: 24/22 22/17                         
  3) 56: 25/20 24/18                          53: 25/20 17/14                         
  4) 21: 25/23 6/5                            34: 25/22 24/20                         
  5) 51: 25/20 6/5                            43: 25/22 6/2                           
  6) 11: 25/24 6/5 24/23 23/22                56:                                     
  7) Doubles => 2                             Takes                                   
  8) 66: 22/16 16/10 21/15 15/9               51: 25/24 13/8                          
  9) 46: 20/14 18/14                          41: 6/5 14/10                           
  10) 42: 25/21 13/11                          24: 8/4 6/4                             
  11) 25: 25/20 11/9                           56:                                     
  12) 24: 20/16 16/14                          62: 25/23 13/7                          
  13) 52: 10/8 13/8                            21: 24/23 7/5                           
  14) 15: 13/8 9/8                             12: 8/6 6/5                             
  15) 65: 14/9 14/8                            26: 13/7 13/11                          
  16) 22: 25/23 9/7 9/7 9/7                    63: 10/7 11/5                           
  17) 25: 6/1 8/6                              16: 22/16 23/22                         
  18) 65: 7/2 8/2                              31: 25/24 16/13                         
  19) 44:                                      33: 5/2 5/2 8/5 13/10                   
  20) 45:                                      64: 22/16 16/12                         
  21) 34: 25/22                                61: 22/16 16/15                         
  22) 53: 25/22 22/17                          63: 25/22 12/6                          
  23) 11: 17/16 4/3 4/3 16/15                  11: 25/24 25/24 15/14 14/13             
  24) 51: 8/7 15/10                            66: 13/7 7/1 7/1 7/1                    
  25) 32: 10/8 8/5                             12: 6/4 4/3                             
  26) 11:                                      21: 5/4 5/3                             
  27) 31:                                      52: 6/4 6/1                             
  28) 63: 25/19 19/16                          61: 4/3                                 
  29) 62: 8/6 16/10                            26: 4/2                                 
  30) 45: 8/3 10/6                             11: 4/3 4/3 3/2 3/2                     
  31) 65: 7/2                                  12: 3/1 2/1                             
  32) 24: 7/3 7/5                              55:                                     
  33) 46: 6/0 6/2                              11: 2/1 2/1 3/2 3/2                     
  34) 13: 6/3 6/5                              44:                                     
  35) 46: 5/0                                  33: 24/21 21/18 18/15 15/12             
  36) 52: 5/3 5/0                              44: 12/8 8/4                            
  37) 23: 5/2 5/3                              25: 24/19 19/17                         
  38) 54: 3/0 3/0                              26: 17/11 11/9                          
  39) 41: 3/2 3/0                              45: 24/19 9/5                           
  40) 45: 3/0 3/0                              11: 19/18 18/17 17/16 16/15             
  41) 65: 3/0 2/0                              32: 15/13 13/10                         
  42) 66: 2/0 2/0 2/0 2/0                      13: 10/7 7/6                            
  43) 12:                                                                              
   Wins 4 points                                                                    

 Game 2
 Deinonychus: 4                           Eran: 0                                 
  1)                                          61: 8/7 13/7                            
  2) 62: 24/22 22/16                          51: 6/5 13/8                            
  3) 13: 24/21 21/20                          46: 25/21 24/18                         
  4) 51: 16/15 20/15                          36: 13/7 21/18                          
  5) 13: 8/5 6/5                              33: 8/5 8/5 7/4 7/4                     
  6) 53: 13/10 10/5                           12: 5/3 6/5                             
  7) 11: 5/4 5/4 6/5 6/5                      23: 6/3 7/5                             
  8) 52: 13/11 11/6                           65: 18/13 24/18                         
  9) 63: 13/10 10/4                           21: 3/2 5/3                             
  10) 21: 4/3 5/3                              11: 5/4 8/7 7/6 6/5                     
  11) 15: 6/1 3/2                              15: 13/8 8/7                            
  12) 24: 5/1 5/3                              43: 4/1 7/3                             
  13) 15: 8/3 3/2                              64: 13/9 13/7                           
  14) 44: 8/4 6/2 13/9 13/9                    61: 3/2 7/1                             
  15) 32: 6/4 4/1                              13: 9/6 6/5                             
  16) 34: 9/5 9/6                              12: 5/3 3/2                             
  17) 21: 6/5 4/2                              26: 18/12 18/16                         
  18) 64: 15/9 9/5                             64: 25/19 12/8                          
  19) 14: 5/1 15/14                            23: 8/5 5/3                             
  20) 33: 14/11 11/8 8/5 3/0                   36: 19/13 13/10                         
  21) 51: 1/0 5/0                              12: 10/8 8/7                            
  22) 61: 1/0 5/0                              14: 7/3 1/0                             
  23) 33: 4/1 4/1 3/0 5/2                      65: 5/0 6/0                             
  24) 21: 1/0 2/0                              64: 4/0 6/0                             
  25) 32: 2/0 2/0                              66: 5/0 4/0 3/0 3/0                     
  26) 14: 2/0 1/0                              63: 3/0 3/0                             
  27) 54: 2/0 1/0                              62: 2/0 2/0                             
  28) 44: 1/0                                                                          
   Wins 1 points and the match                                                      


`

var last_min_max = [0, 0]

const extra_data = reactive({
    position: test_states[0],
    selected_game: 0,
    match: null,

    animation_status: "idle",
    current_move_id:0,
    min_max_move_id: [0, 0],

    lazy:true,
});

onMounted(async () =>{ 
    const match_id = route.params.match_id;
    console.log(match_id);
    if(match_id){
        await getMatch(match_id);
    }
    extra_data.lazy = false;
    new MatFileParser(test_matfile);
});

onUnmounted(() =>{ 
    extra_data.counter = 0;
    if(props.match_id){
        clearInterval(counter_interval_id);
    }
});

function get_states(){
    if(extra_data.match){
        const states =  get_states_from_game(extra_data.match.games[extra_data.selected_game]);
        return states.map((x) => x[0].toPositionString());
    }
    return ["11ccccchhhjjjjj:66666888dddddoo:N0N:42:W:IB:0:0:1:0"];
}

function downloadBlob(blob, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = URL.createObjectURL(blob);;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

async function states_to_png(states, fname){
    const node = document.getElementById('board-capture');
    const frames = [];
    const delays = [];
    for(let position of states){
        const data = await toPixelData(node, {
            pixelRatio:1,
        });
        frames.push(data);
        extra_data.position = position;
        await nextTick();
        delays.push(800);
    }
    // console.log("DATA length", data.length, data.length/4, (data.length/4)/node.offsetWidth);
    const png = UPNG.encode(frames, node.offsetWidth, node.offsetHeight, 0, delays);
    const blob = new Blob([png]);
    downloadBlob(blob, `${fname}.png`);
}

async function states_to_gif(states, fname, delay=1200){
    const node = document.getElementById('board-capture');
    const width = node.offsetWidth;
    const height = node.offsetHeight;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    const encoder = new GIFEncoder(width, height);
    encoder.setDelay(delay);
    encoder.start();

    for(var i=extra_data.min_max_move_id[0]; i < extra_data.min_max_move_id[1]; i++){
        extra_data.current_move_id = i;
        const data = await toPixelData(node, {
            pixelRatio:1,
        });
        ctx.putImageData(new ImageData(data, width, height), 0, 0);
        if( i == extra_data.min_max_move_id[1] - 1){
            encoder.setDelay(delay*2);
        }
        encoder.addFrame(ctx);

        await nextTick();
    }
    // Now draw the end-screen
    const logo = new Image();
    const logo_url = new URL(`/src/assets/images/logo_background.svg`, import.meta.url);
    logo.src = logo_url 
    await logo.decode();
    console.log(logo_url, logo);

    ctx.fillStyle = "#1E2129";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const logo_factor = canvas.width / logo.naturalWidth;

    console.log(logo.naturalWidth, logo.naturalHeight);

    const destination_h = Math.floor(logo_factor * logo.naturalHeight);
    const destination_w = canvas.width;
    const destination_y = Math.floor(canvas.height/2 - destination_h/2);

    console.log(canvas.width, logo_factor, destination_w, destination_h, destination_y);

    ctx.drawImage(logo, 0, destination_y, 
                  destination_w, destination_h);

    encoder.setDelay(delay*4);
    encoder.addFrame(ctx);

    encoder.finish()
    const gif_data = encoder.out.getData();
    const blob = new Blob([gif_data]);
    downloadBlob(blob, `${fname}.gif`);
}

async function getMatch(match_id){
    const response = await fetch(app_server + `/match/${ match_id }/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const match_data = await response.json();
    const match = new Match();
    match.from_json(match_data.match);
    extra_data.info = match_data.info;
    extra_data.selected_game = 0;
    extra_data.match = match;
    extra_data.min_max_move_id[1] = get_states().length - 1
    last_min_max = extra_data.min_max_move_id;
}

function get_states_from_game(game){
    return game.states_log.filter(
        (x) => ["IB", "R", "D", "A", "P", "G", "M"].includes(x[0].game_state));
}

async function export_game(){
    extra_data.animation_status = "animating";
    console.log(extra_data.animation_status);
    const game = extra_data.match.games[extra_data.selected_game];
    const states = get_states_from_game(game).map( (x) => x[0].toPositionString());
    // await states_to_png(states, `text.png`);
    await states_to_gif(states, `text.png`);
    extra_data.animation_status = "idle";
}

function changed_min_max(new_min_max){
    console.log("Changed", last_min_max, new_min_max);
    if(new_min_max[0] != last_min_max[0]){
        extra_data.current_move_id = new_min_max[0];
    }else{
        extra_data.current_move_id = new_min_max[1];
    }
    last_min_max = new_min_max.slice();
}


</script>
<template>
<Header />
<div class="container mx-auto flex flex-col gap-y-4">
    <div class="flex justify-center">
        <button class="btn btn-blue" 
            :disabled="extra_data.animation_status != 'idle'"
            @click="() => export_game()"
        >
            Capture ({{extra_data.animation_status}})
        </button>
        <select v-model="extra_data.selected_game" v-if="extra_data.match">
            <option v-for="(game, index) in extra_data.match.games" :value="index" :key="index">
                Game {{ index + 1 }}
            </option>
        </select>
    </div>
    <div class="flex justify-center">
        <Board class="w-[960px] h-[640px]" style="margin: 0;"
            boardID='capture'
            :positionString="get_states()[extra_data.current_move_id]"
            player_color="W"
        />
    </div>
    <div class="minmax-slider">
        <Slider 
            v-model="extra_data.min_max_move_id"
            :min="0"
            :max="get_states().length - 1"
            :lazy="extra_data.lazy"
            @update="changed_min_max"
        />
    </div>
</div>
</template>

<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.minmax-slider{
    --slider-connect-bg: var(--field-color);
    --slider-tooltip-bg: var(--field-color);
}
</style>

