<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'
import { useHead } from '@unhead/vue'
import markdownit from 'markdown-it'

import Header from './Header.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var slug;

const props = defineProps({
    post: {
        type: Object,
        default: null,
    }
});

const extra_data = reactive({
    post: {content: "", meta_tags: {}},
});


useHead(() => extra_data.post.meta_tags);

watch( () => props.post, (post, prevPost) => {
    extra_data.post = post;  
});

onMounted(() => {
    if(props.post != null){
        extra_data.post = props.post;
    }else{
        slug = useRoute().params.slug;
        get_post();
    }
});

async function get_post(){
    if(slug == null){
        return;
    }
    var url = app_server + `/blog/${slug}/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
        },
    });
    
    const blog_data = await response.json();
    if(blog_data.status == "success"){
        extra_data.post = blog_data.post;
    }
    
    console.log(blog_data);
}
</script>

<template>
    <Header v-if="props.post == null" />
    <div v-if="extra_data.post.slug == null"
         class="text-2xl mx-auto text-center mt-16"
    >
        Could not find the Blog post.
    </div>
    <div class="md:container md:max-w-[90ch] mx-auto px-2">
        <div class="flex justify-between justify-items-center items-center align-middle">
            <h1>{{ extra_data.post.title }}</h1>
            <router-link :to="{name:'blog-list'}">
                <p>Home</p>
            </router-link>
        </div>
    <div v-if="extra_data.post.author" class="flex gap-x-6 -mt-3 opacity-70 hover:opacity-100 transition-all text-sm">
        <div class="flex gap-x-1 items-center">
            <UserCircleIcon class="w-4 h-4" /> 
            <span>{{ extra_data.post.author.username }}</span>
        </div>
        <div class="flex gap-x-1 items-center">
            <ClockIcon class="w-4 h-4" /> 
            <span>{{ new Date(extra_data.post.update_time*1000).toDateString() }}</span>
        </div>
    </div>
    <div class="my-4 text-lg font-thin">
        {{ extra_data.post.summary }}
    </div>
    <MarkdownRenderer :markdown="extra_data.post.content" :save="false" />
    </div>
</template>

<style scoped>
    h1, :deep(h1){
        @apply text-4xl font-bold mt-6 mb-6
    }
    h2, :deep(h2){
        @apply text-2xl font-semibold my-4
    }
    h3, :deep(h3){
        @apply text-xl font-semibold
    }
    :deep(ol){
        @apply list-decimal list-inside my-4
    }
    :deep(ul){
        @apply list-disc list-inside my-4
    }
    :deep(blockquote){
        @apply px-4 my-4 border-s-4 border-gray-300 bg-gray-50;
    }
    :deep(p){
        @apply mb-6
    }
    :deep(a){
        @apply underline hover:text-field-color
    }
</style>
