<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'


const extra_data = reactive({
    message: "Welcome join or create a match to start playing.",
    user:{},
    open_account_details:false,
    total_nrof_users:0,
    offset:0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const quiz_list = reactive([]);
onMounted(() => {
    load_quiz_list();
});

async function load_quiz_list(){
    var url = app_server + "/admin/quiz/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    
    
    const data = await response.json();
    console.log("Quiz", data);
    quiz_list.length = 0;// empty the users list
    if(data.quizzes){
        quiz_list.push(...data.quizzes);
        extra_data.total_nrof_quizzes = data.total_nrof_quizzes;
    }
}

async function delete_quiz(quiz_id){
    if(!window.confirm(`Are you sure you want to delete quiz ${quiz_id}`)){
        return;
    }
    const response = await fetch(app_server + `/admin/quiz/${ quiz_id }/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "revert_solutions": true,
        }),
    });
    const data = await response.json();
    console.log(data);
}

function next_page(){
    extra_data.offset += 100;
    load_quiz_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 100);
    load_quiz_list();
}

function get_eq_lost(quiz){
    var eq_lost = 0;
    for(let puzzle_id in quiz.solves){
        eq_lost += quiz.solves[puzzle_id].eq_difference;
    }
    return eq_lost;
}

</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
<p>Total quizzes: {{ extra_data.total_nrof_quizzes }}</p>
<p>Offset: {{ extra_data.offset }}</p>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">ID</th> 
        <th scope="col" class="sticky top-0 z-10">User ID</th> 
        <th scope="col" class="sticky top-0 z-10">Type</th> 
        <th scope="col" class="sticky top-0 z-10">Puzzles/Solved</th> 
        <th scope="col" class="sticky top-0 z-10">Max EQ lost</th> 
        <th scope="col" class="sticky top-0 z-10">Create Time</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="quiz_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No quizzes
    </td>
    </tr>
    <tr v-for="quiz, index in quiz_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <a href="#" v-if="quiz.quiz_id">
                <router-link :to="{name: 'quiz', params:{quiz_id: quiz.quiz_id}}">
                    {{ quiz.quiz_id }}
                </router-link>
            </a>
        </td>
        <td class="text-right">{{ quiz.user_id }}</td>
        <td class="text-right">{{ quiz.quiz_type }}</td>
        <td class="text-center">{{ quiz.puzzles.length }} / {{ Object.keys(quiz.solves).length }}</td>
        <td class="text-right">{{ get_eq_lost(quiz).toFixed(2) }}</td>
        <td class="text-right">{{ quiz.max_eq_lost || 0 }}</td>
        <td class="text-right">{{ (new Date(quiz.start_time * 1000)).toISOString() }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
