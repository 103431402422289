<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon} from '@heroicons/vue/24/solid'
import PatronIcon from './PatronIcon.vue'

import UserTag from './UserTag.vue'

const router = useRouter();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const props = defineProps({
    user: { // Used if multiple boards are shown in the same view
        type: Object,
        default: null,
    },
});

const user_data = reactive({
    online: false,
    username : "",
    tag: "",
    is_patron: "",
});

watch(props, () => {
    if(props.user == null){
        return
    }else{
        user_data.user_id = props.user.user_id;
        user_data.online = userStore.is_online(props.user.user_id);
        user_data.username = props.user.username;
        user_data.tag = props.user.tag;
        user_data.is_patron = props.user.is_patron;
    }

});

watch(userStore.users_online, () => {
    user_data.online = userStore.users_online.includes(user_data.user_id);
});

onMounted(async () => {
    if(props.user == null){
        await userStore.loadUser();
        user_data.user_id = userStore.info.user_id;
        user_data.online = userStore.is_online(userStore.info.user_id);
        user_data.username = userStore.info.username;
        user_data.tag = userStore.info.tag;
        user_data.is_patron = userStore.info.is_patron;
    }else{
        user_data.user_id = props.user.user_id;
        user_data.online = userStore.is_online(props.user.user_id);
        user_data.username = props.user.username;
        user_data.tag = props.user.tag;
        user_data.is_patron = props.user.is_patron;
    }
});

</script>
<template>
    <div class="flex items-center gap-x-1">
        <PatronIcon v-if="user_data.is_patron"
                    class="h-5 w-5 -mr-1 " 
                    :class="{
                        'fill-field-color stroke-field-color' : user_data.online,
                        'fill-case-med-color stroke-case-med-color' : !user_data.online,
                    }"
        />
        <div class="h-5 w-5 p-1 -mr-1 relative" v-else>
            <div class="rounded-full w-full h-full" 
                :class="{'border-2 bg-field-med-color border-field-color' : user_data.online}"
            />
        </div>
        {{ user_data.username }} <UserTag :tag="user_data.tag" />
    </div>
</template>

<style>
</style>

