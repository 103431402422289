import {get_random_move, find_engine_move} from './bot_helper.js'

export class AlexBot{
    name = "Alex";
    elo = 1000;
    match_length = 3;
    description = "Alex"

    engine_id = "gnubg";
    analysis_config = "1ply";
    analysis_data = {};

    app_server_url = "";
    
    handle_analysis(analysis_data){
        this.analysis_data = analysis_data;
    }
    
    async find_move(board){
        var action = null;
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
            const config= {
                "config": this.analysis_config,
                "engine_id": this.engine_id,
            };
            return find_engine_move(this, board, config, this.app_server_url);

        } else if(board.game_state == "D"){ // take pass
            const config= {
                "config": this.analysis_config,
                "engine_id": this.engine_id,
            };
            return find_engine_move(this, board, config, this.app_server_url);
        } else if(board.game_state == "C"){ // roll double
            const config= {
                "config": this.analysis_config,
                "engine_id": this.engine_id,
            };
            
            if(Math.random() < 0.60){
                action = find_engine_move(this, board, config, this.app_server_url);
            }else{
                action = "roll";
            }
        }
        return action;
    }
}
