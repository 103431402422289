<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Message from './Message.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const extra_data = reactive({
});

const avatar_data = reactive({
    avatar_id: "0000000000000000000000000".split(""),
    available_checkers: {}
});

var avatar_color_map = {
};

function get_checker_style(color_id){
    const color_style = `background-color:${avatar_color_map[color_id]}`
    return color_style;
}

function get_available_checkers(){
    // Do a GET request to get all available checkers
    return userStore.achievements.checkers;
}

function count_occurences(avatar_id, color_id){
    return (avatar_id.filter(x => x == color_id).length);
}

function checkers_left(color_id){
    const available = avatar_data.available_checkers[color_id];
    const count = count_occurences(avatar_data.avatar_id, color_id)
    return available - count;
}

function set_color(index, color_id){
    if(checkers_left(color_id) <= 0){
        return; 
    }
    avatar_data.avatar_id[index] = color_id;
}

async function save_avatar(){
    alert("Please keep in mind that your avatar is seen by the rest of the community. Offensive avatars will result in a ban.");
    const response = await fetch(app_server + `/user/avatar/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "avatar_id": avatar_data.avatar_id.join(""),
        }),
    });
    
    const data = await response.json();
    if(data.status == "success"){
        messageStore.alertUser("Avatar saved", "The new avatar has been saved successfully");
        userStore.info.avatar = avatar_data.avatar_id.join("");
    }else{
        messageStore.alertUser("Avatar not saved", data.message);
    }
}

function reset_avatar(){
    avatar_data.avatar_id = "0000000000000000000000000".split("");
}

function get_case_color(){
    console.log(userStore.preferences);
    if(userStore.preferences.board){
        return userStore.preferences.board.color.case;
    }else{
        return "#EEEEEE";
    }
}

onMounted(async () => {
    await Promise.allSettled([
        userStore.loadUser(),
        userStore.loadAchievements(),
        userStore.loadPreferences(),
    ])

    const avatar_str = userStore.info.avatar;  
    if(avatar_str && avatar_str.length == 25){
        avatar_data.avatar_id = avatar_str.split("");
    }
    console.log(userStore.info);
    console.log(userStore.achievements);
    avatar_data.available_checkers = get_available_checkers();
    avatar_color_map = userStore.achievements.color_keys;
});

</script>
<template>
    <Header :user="extra_data.logged_in_user"/>
    <Message />
    <div class="container mx-auto max-w-[70svh] flex flex-col gap-y-4">
    <div>
        <h1 class="text-4xl font-semibold">Build your avatar</h1>
    <p class="font-thin">
        You can create your avatar by choosing a colour and
        clicking on the circle you want to colour. You can unlock more colours by
        earning achievements.
    </p>
    </div>
    <div class="flex gap-x-2 justify-end">
        <button class="btn btn-blue" @click="reset_avatar()">
            Reset 
        </button>
        <button class="btn btn-blue" @click="save_avatar()">
            Save
        </button>
    </div>
    <div class="grid grid-cols-5 aspect-square w-full border" :style="{'background-color': get_case_color()}">
        <div v-for="c, index in avatar_data.avatar_id" 
            class="rounded-full border"
            :style="get_checker_style(c)"
            @click="set_color(index, extra_data.selected)"
        >
        </div>
    </div>
    <div class="h-24 max-h-[20svh] w-full overflow-x-auto flex relative">
        <div v-for="(count, color_id) in avatar_data.available_checkers" 
            :class="{'disabled': checkers_left(color_id) <= 0, 
                     'opacity-100': extra_data.selected == color_id,
                     'opacity-80': extra_data.selected != color_id,
                    }"
            @click="extra_data.selected = color_id"
            class="aspect-square h-full max-h-[20vw] rounded-full relative cursor-pointer hover:opacity-100"
        >
            <div 
                :style="get_checker_style(color_id)"
                :class="{'ring': extra_data.selected == color_id}"
                class="aspect-square h-9/12 m-4 rounded-full border-2">
            </div>
            <div class="text-lg font-semibold mx-auto top-0 left-0 absolute"
            >
                {{ checkers_left(color_id) }}
            </div>
        </div>
    </div>
    </div>
</template>

<style>
</style>
