<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const extra_data = reactive({
    message: "Welcome join or create a match to start playing.",
    user:{},
    open_account_details:false,
    total_nrof_users:0,
    offset:0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const analyser_list = reactive([]);
onMounted(() => {
    load_analyser_list();
});

async function load_analyser_list(){
    var url = app_server + "/admin/analyser/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    console.log(data);
    analyser_list.length = 0;// empty the users list
    if(data.analysers){
        analyser_list.push(...data.analysers);
        extra_data.total_nrof_analysers = data.total_nrof_analysers;
    }
}

async function create_analyser(){
    if(!window.confirm(`Are you sure you want to create an analyser`)){
        return;
    }
    const response = await fetch(app_server + `/admin/analyser/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    console.log(data);
}

function next_page(){
    extra_data.offset += 100;
    load_analyser_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 100);
    load_analyser_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div>
        <button class="btn btn-blue"
            @click="create_analyser()"
        >
            Create Analyser
        </button>
        <div>
            <div>Total analysers: {{ extra_data.total_nrof_analysers }}</div>
            <div>Offset: {{ extra_data.offset }}</div>
        </div>
    </div>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">ID</th> 
        <th scope="col" class="sticky top-0 z-10">Name</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">User</th> 
        <th scope="col" class="sticky top-0 z-10">Score</th> 
        <th scope="col" class="sticky top-0 z-10">Trust</th> 
        <th scope="col" class="sticky top-0 z-10">Total Time</th> 
        <th scope="col" class="sticky top-0 z-10">Total Decisions</th> 
        <th scope="col" class="sticky top-0 z-10">Last Seen</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr v-for="analyser, index in analyser_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left text-sm">
            <router-link 
                :to="{name: 'admin-analyser-detail', params:{
                    analyser_id: analyser.analyser_id}
                }"
            >
                {{ analyser.analyser_id.slice(0,5) }}
            </router-link>
        </td>
        <td class="text-left">{{ analyser.name }}</td>
        <td class="text-left">{{ analyser.status }}</td>
        <td class="text-right font-mono text-sm">
            <router-link v-if="analyser.user_id"
                :to="{name: 'admin-user-detail', params:{
                    user_id: analyser.user_id}
                }"
            >
                {{ analyser.user_id.slice(0,5) }}
            </router-link>
            <span v-else>No User</span>
        </td>
        <td class="text-right">{{ analyser.score }}</td>
        <td class="text-right">{{ analyser.trust.toFixed(1) }}</td>
        <td class="text-right">{{ analyser.total_time_analysed}}</td>
        <td class="text-right">{{ analyser.total_decisions_analysed}}</td>
        <td class="text-right">
            {{ (new Date(analyser.last_seen * 1000)).toISOString().slice(2,16) }}
        </td>
        <td class="text-left text-sm">{{ analyser.analyser }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>


