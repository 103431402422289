export class TysonBot{
    name = "Tyson";
    elo = 250;
    match_length = 1;
    description = "Lisa doesn't like to take risks.";

    async find_move(board){
        var action = null;
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
            const valid_states = Object.values(board.getValidStates());
            if(valid_states.length == 0){
                action = board;
            }else{
                const actions = valid_states.map((x) => x[0][0]);
                const max_opp_pipcount = Math.max(...actions.map((x) => x.get_pipcount().W));

                
                const max_opp_pip_actions = actions.filter(
                    (x) => x.get_pipcount().W == max_opp_pipcount);

                action = max_opp_pip_actions[Math.floor(Math.random() * max_opp_pip_actions.length)];
            }
        } else if(board.game_state == "D"){
            if(Math.random() < 0.60){
                action = "take";
            }else{
                action = "pass";
            }
        } else if(board.game_state == "C"){
            if(Math.random() < 0.10){
                action = "double";
            }else{
                action = "roll";
            }
        }
        return action;
    }

    count_direct_shots(board){
        var direct_shots = 0;
        for(let point_id in board.points){
            const point = board.points[board.get_point_index(point_id)];
            if(point.color == "W" || point.nrof_stones != 1){
                continue;
            }

            for(let i=1; i<=6; i++){
                if(point_id - i <= 0){
                    continue;
                }
                if(board.points[board.get_point_index(point_id - i)].color == "W"){
                    direct_shots++;
                }
            }
        }
        return direct_shots;
    }
}
