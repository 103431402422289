<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var match_id;

const extra_data = reactive({
    match: {}
});


onMounted(() => {
    match_id = useRoute().params.match_id;
    load_match();
});

async function save_match(){
    var url = app_server + `/admin/match/${match_id}/`;
    const data = extra_data.match;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const match_data = await response.json();
    if(match_data.match){
        extra_data.match = match_data.match;
    }
    console.log(match_data);
}

async function delete_analysis(){
    var url = app_server + `/admin/analysis/${match_id}/clear/`;
    const data = {};

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const match_data = await response.json();
    console.log(match_data);
}

async function load_match(){
    var url = app_server + `/admin/match/${match_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log(data);
    if(data.match){
        extra_data.match = data.match;
    }
}

</script>

<template>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-matches'}">
            Match List
        </router-link>
    </button>
    <button @click="save_match()" class="btn btn-blue">Save</button>
    <button @click="delete_analysis()" class="btn btn-blue">Delete Analysis</button>
    <form>
        <div v-for="value,field in extra_data.match">
            <label :for="field"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                {{ field }}
            </label>
            
            <textarea 
                v-model="extra_data.match[field]"
                v-if="field == 'description'"
                :id="field"
            ></textarea>
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="extra_data.match[field]"
                v-else
                :id="field"
            />
        </div>
    </form>
</template>

<style>
</style>
