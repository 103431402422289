<script setup>
import Tooltip from './Tooltip.vue'

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import {CalendarIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const emit = defineEmits([
    'hide',
]);


const props = defineProps({
    puzzle_id: {
        type: String,
        default: null,
    },
});

const extra_data = reactive({
    daily_editor : false,
});

onMounted(async () => {
    userStore.loadUser().then( () => {
        if(userStore.has_permission("daily", "editor")){
            extra_data.daily_editor = true;
        }
    });
});

async function add_daily_puzzle(){
    if(! confirm("Are you sure you want to add this puzzle to the daily puzzle queue?")){
        return;
    }
    const response = await fetch(app_server + `/puzzle/${ props.puzzle_id }/daily/`, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt")
        },
        body: "{}",
    });
    const puzzle_data = await response.json();
    console.log(puzzle_data);
    if(puzzle_data.status == "success"){
        messageStore.alertUser(
            "Daily Added", 
            puzzle_data.message
        );
    }else{
        messageStore.alertUser(
            "Error adding Daily", 
            puzzle_data.message
        );
    }

    return puzzle_data;
}

</script>
<template>
<Tooltip v-if="extra_data.daily_editor"
    text="Schedule this puzzle as a Daily puzzle in the next available date.">
    <CalendarIcon 
        title="Schedule as Daily Puzzle"
        class="w-6 h-6 hover:text-field-color cursor-pointer" 
        @click="add_daily_puzzle(puzzle_id)"
    />
</Tooltip>
</template>

<style scoped>
</style>

