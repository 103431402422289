import {get_random_move, find_engine_move} from './bot_helper.js'

export class PatrickBot{
    name = "Patrick";
    elo = 900;
    match_length = 1;
    description = "Patrick enjoys playing, but does not "

    engine_id = "gnubg";
    analysis_config = "1ply";
    analysis_data = {};

    app_server_url = "";
    
    handle_analysis(analysis_data){
        this.analysis_data = analysis_data;
    }
    
    async find_move(board){
        var action = null;
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
            const ranks = [
                           0,0,0,0,0, 0,0,0,0,0, 
                           0,0,0,0,0, 0,0,0,0,0, 
                           0,0,0,0,0, 0,0,0,0,0, 
                           0,0,0,0,0, 0,0,0,0,0, 
                           1,1,1,1,1, 1,1,1,1,1, 
                           2,2,2,2,2, 3,3,3,4,4, 5];

            const rank_index = Math.floor(Math.random() * ranks.length) + 1;
            const rank = ranks[rank_index];

            console.log("Rank:", rank_index, rank);

            const config= {
                "config": this.analysis_config,
                "engine_id": this.engine_id,
            };
            return find_engine_move(this, board, config, this.app_server_url, rank);

        } else if(board.game_state == "D"){
            if(Math.random() < 0.60){
                action = "take";
            }else{
                action = "pass";
            }
        } else if(board.game_state == "C"){
            if(Math.random() < 0.10){
                action = "double";
            }else{
                action = "roll";
            }
        }
        return action;
    }
}
