export class MatFileParser {
    constructor(matfile_str){
        const games = this.get_games(matfile_str);
    }

    get_games(str){
        const games = [];
        let current_game = [];
        for(let line of str.split("\n")){
            line = line.trim()
            console.log(line);
            
            if(line.startsWith("Game")){
                games.push(current_game.slice());
                current_game = [];
            }
            current_game.push(line);
        }
        games.push(current_game);
        console.log(games);
        return games;
    }
    
    get_moves(game){
        for(let line in game){
            
        }
        
    }
}
