
export class MartinBot{
    name = "Martin";
    elo = 200;
    match_length = 1;
    description = "After learning chess Martin decided to try his luck in backgammon. Are you able to see through his plays?";

    async find_move(board){
        var action = null;
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
            const valid_states = Object.values(board.getValidStates());
            if(valid_states.length == 0){
                action = board;
            }else{
                const random_state = valid_states[Math.floor(Math.random() * valid_states.length)];
                action = random_state[0][0];
                console.log(action);
            }
        } else if(board.game_state == "D"){
            if(Math.random() < 0.60){
                action = "take";
            }else{
                action = "pass";
            }
        } else if(board.game_state == "C"){
            if(Math.random() < 0.10){
                action = "double";
            }else{
                action = "roll";
            }
        }
        return action;
         
    }
}
