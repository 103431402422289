import {filter_min_direct_shot_actions, filter_running_actions} from './bot_helper.js'
import {Engine} from '../engine.js'
import {BoardState} from '../board.js'

export class LolaBot{
    name = "Lola";
    elo = 300;
    match_length = 1;
    description = "Lola has learned from het previous mistakes.";

    async find_move(board){
        var action = null;
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll

            const engine = new Engine();
            const moves = engine.get_move(board, 1);
            console.log(moves);
            console.log(engine.homeboard(board, "B"));
            console.log(engine.homeboard(board, "W"));
            if(moves.length == 0){
                action = board.copy();
            }else{
                action = new BoardState(moves[0][0]);
            }

        } else if(board.game_state == "D"){
            if(Math.random() < 0.60){
                action = "take";
            }else{
                action = "pass";
            }
        } else if(board.game_state == "C"){
            if(Math.random() < 0.10){
                action = "double";
            }else{
                action = "roll";
            }
        }
        return action;
    }

    count_direct_shots(board){
        var direct_shots = 0;
        for(let point_id in board.points){
            const point = board.points[board.get_point_index(point_id)];
            if(point.color == "W" || point.nrof_stones != 1){
                continue;
            }

            for(let i=1; i<=6; i++){
                if(point_id - i <= 0){
                    continue;
                }
                if(board.points[board.get_point_index(point_id - i)].color == "W"){
                    direct_shots++;
                }
            }
        }
        return direct_shots;
    }
}

