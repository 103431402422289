import {find_engine_move} from './bot_helper.js'

export class WildBGBot{
    name = "WildBG 1-ply";
    elo = 1000;
    engine_id = "wildbg";
    match_length = 1;
    analysis_config = "1ply";
    analysis_data = {};

    app_server_url = "";
    
    handle_analysis(analysis_data){
        this.analysis_data = analysis_data;
    }
    
    async find_move(board){
        const config= {
            "config": this.analysis_config,
            "engine_id": this.engine_id,
        };
        return find_engine_move(this, board, config, this.app_server_url);
    }
}

