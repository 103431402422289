<script setup>
import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'
import {CheckCircleIcon, XCircleIcon} from '@heroicons/vue/24/solid'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;
import {reactive, onMounted} from 'vue'

const extra_data = reactive({
    checkout_loading: false,
    portal_loading: false,
});

async function start_checkout(donation_type){
    extra_data.checkout_loading = true;
    var header = {
        "Content-Type": "application/json",
    };
    if(localStorage.getItem("jwt")){
        header["Authorization"] = "Bearer " + localStorage.getItem("jwt");
    }
    const response = await fetch(app_server + `/donation/checkout/${donation_type}/`, {
        method: "POST",
        mode: "cors",
        headers: header,
    });
    const data = await response.json();
    if(data.status == "success"){
        window.location.href = data.redirect_url;
    }else if(data.status == "error"){
        messageStore.alertUser("Error", data.message);
    }
    extra_data.checkout_loading = false;
}

async function get_portal(){
    extra_data.portal_loading = true;
    var header = {
        "Content-Type": "application/json",
    };
    if(localStorage.getItem("jwt")){
        header["Authorization"] = "Bearer " + localStorage.getItem("jwt");
    }
    const response = await fetch(app_server + `/donation/portal/`, {
        method: "POST",
        mode: "cors",
        headers: header,
    });
    const data = await response.json();
    if(data.status == "success"){
        window.location.href = data.redirect_url;
    }else if(data.status == "error"){
        messageStore.alertUser("Error", data.message);
    }
    extra_data.portal_loading = false;
}

onMounted(()=>{
    userStore.loadUser();
});


</script>
<template>
<Header />
<div class="container mx-auto mb-8 px-2 lg:px-0 lg:w-1/2 flex flex-col gap-y-8">
    <h1 class="text-4xl text-center font-bold mt-16">
        Support OpenGammon
    </h1>
    <p class="font-light text-xl my-4">
        Support us to run OpenGammon and develop new features. Make a one time
        donation, or become a patron and get an OpenGammon patron badge. See the 
        <router-link :to="{name: 'features'}"
            class="text-field-color hover:underline"
        >
            features page
        </router-link> for the
        differences between a patron account and a normal account (hint: a badge).
    </p>
    <div class="grid grid-cols-2 gap-x-4 my-8">
        <div 
            class="flex flex-col items-center justify-between py-4
            rounded bg-case-light-color h-64 hover:bg-field-light-color"
        >
            <h2 class="text-2xl font-bold">Become a Patron</h2>
            <div class="flex flex-col items-center">
                <span class="text-4xl">&euro;60,- </span>
                <span class="font-thin">per year</span>
            </div>
            <p class="font-thin" v-if="! userStore.authenticated">
                You need to be logged in to become a Patron
            </p>
            <ButtonLoad 
                class="btn btn-blue text-2xl h-12"
                @click="start_checkout('patron')"
                :loading="extra_data.checkout_loading"
                :disabled="!userStore.authenticated"
            >
                Subscribe
            </ButtonLoad>
        </div>
        <div 
            class="flex flex-col items-center justify-between py-4
            rounded bg-case-light-color h-64 hover:bg-field-light-color"
        >
            <h2 class="text-2xl font-bold">One-time Donation</h2>
            <div class="flex flex-col items-center">
                <span class="text-4xl">Any amount</span>
            </div>
            <ButtonLoad 
                    class="btn btn-blue text-2xl h-12"
                    @click="start_checkout('donate')"
                    :loading="extra_data.checkout_loading"
            >
                One-time Donation 
            </ButtonLoad>
        </div>
    </div>
    <div class="mt-8 flex flex-col items-center justify-center"
         v-if="userStore.authenticated && userStore.info.is_patron"
        >
        <p>
            You can manage your subscription in the:
        </p>

        <div>
        <ButtonLoad 
            class="btn btn-blue text-2xl h-10 mt-4"
            @click="get_portal()"
            :loading="extra_data.portal_loading"
        >
            Portal
        </ButtonLoad>
        </div>
    </div>

</div>

</template>

<style scoped>
</style>


