<script setup>

import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Avatar from './Avatar.vue'
import MatchList from './MatchList.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const extra_data = reactive({
    rating_list: [],
    elo_list: [],
    er_list: [],
    user_info: {},
    user: {},
    logged_in_user: {},
    user_id: null,
});

onMounted(async () => {
    userStore.loadUser();
    get_achievements()
});

async function get_achievements(){
    const response = await fetch(app_server + `/achievement/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const achievement_info = await response.json();
    
    extra_data.achievements = achievement_info.categories; 
    extra_data.color_data = achievement_info.colors; 
    
    console.log(achievement_info);
}

</script>

<template>
    <div class="container mx-auto px-4" :key="extra_data.user_id">
    <Header :user="extra_data.logged_in_user"/>
        <h3 class="text-xl font-semibold mb-4">Achievements</h3>
        <div class="flex flex-col gap-y-8">
        <div v-for="achievements, category in extra_data.achievements" 
            class="flex flex-col gap-y-6">
            <div class="flex justify-between w-full ">
                <h2 class="text-2xl font-semibold">{{ category }}</h2>
                <div class="flex gap-x-1 items-center justify-end">
                    <span v-for="j in Array(5).keys()"
                          class="h-6 w-6 rounded-full" 
                          :style="{'background-color': extra_data.color_data[achievements[0].colors[j]]}" />
                </div> 
            </div> 
            <div class="flex justify-between" 
                 v-for="achievement, i in achievements" :key="i">
                <div class="flex flex-col">
                    <span class="text-lg font-semibold">{{ achievement.name }}</span>
                    <span class="flex gap-x-2 font-thin">
                        <span v-for="level in achievement.levels">{{ level }}</span>
                    </span>
                    <span class="text-base text-wrap">{{ achievement.description }}</span>
                </div> 
            </div> 
        </div>
        </div>
    </div>
</template>

<style>
</style>
