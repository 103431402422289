import { defineStore } from 'pinia'

const app_server = import.meta.env.VITE_APP_SERVER;

export const useGameSettingStore = defineStore('gamesetting', {
    state: () => { return {
        settings: {
            sound: false,
            chat: true,
        },
    };},
    getters: {
    },
    actions:{

    }
});


