export function debounce(fn, wait){
    let timer = null;
    if(wait == null){
        wait=500;
    }
    return (...args) => {
        if(timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        timer = setTimeout( () => {
            fn.apply(this, args); // call the function if time expires
        }, wait);
    }
}

