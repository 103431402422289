<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var user_id;

const extra_data = reactive({
    result: {}
});

const mail_data = reactive({
    title: "Update",
    body: "",
    test: true,   
});

onMounted(() => {
});

async function mail_users(){
    var url = app_server + `/admin/user/sendmail/`;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(mail_data),
    });
    
    const user_data = await response.json();
    console.log(user_data);
    extra_data.result = user_data;
}
</script>

<template>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-users'}">
            User List
        </router-link>
    </button>
    <div class="mx-auto w-1/2">
    <h1>Mail</h1>
    <h2 v-if="extra_data.result.status == 'success'">
        Mails sent ({{ extra_data.result.emails_sent}} / {{ extra_data.result.total }}) in  {{ extra_data.result.time.toFixed(1) }} seconds
    </h2>
    <form>
        <div class="py-4">
            <label for="title"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                Test
            </label>
            <input type="checkbox"
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="mail_data.test" id="title"/>
            <p class="text-sm font-thin">When checked we only send the email to the admin submitting the form. </p>

        </div>
        <div>
            <label for="title"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                Title
            </label>
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="mail_data.title" id="title"/>
        </div>
        <div>
            <label for="body"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                Body
            </label>
            <textarea 
                class="block h-96 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="mail_data.body" id="body"></textarea>
        </div>
    </form>
    <button @click="mail_users()" class="btn btn-blue my-4">Send Mails</button>
    <div class="font-thin text-sm py-8">
        <p>You can use the following variables:</p>
        <ul class="list-disc"> 
            <li>user_id</li>
            <li>username</li>
            <li>email </li>
            <li>rating </li>
            <li>elo </li>
            <li>er </li>
            <li>experience </li>
            <li>status </li>
            <li>date_joined </li>
            <li>analysis_config </li>
        </ul>
        <pre>{ variable_name }</pre>
    </div>
    </div>
    <div>
        {{ extra_data.result }} 
    </div>
</template>

<style>
</style>
