<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import AccountDetails from './AccountDetails.vue'


const extra_data = reactive({
    message: "Welcome join or create a match to start playing.",
    user:{},
    open_account_details:false,
    offset:0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const match_list = reactive([]);
onMounted(() => {
    reload();
});

function reload(){
    load_user();
    load_match_list();
}

async function load_user(){
    const response = await fetch(app_server + "/user/", {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
            return null;
        }
        return response;    
    });
    if(response === null){
        extra_data.user = null;
        return;
    }
    const user_data = await response.json();
    extra_data.user = user_data.user;
    if(extra_data.jwt){
        localStorage.setItem('jwt', user_data.jwt);
    }
}

async function load_match_list(){
    if(document.visibilityState == "hidden"){
        return;
    }
    match_list.length = 0;// empty the match list
    
    var url = app_server + "/user/matches/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;    
    }).catch(error => {
        console.error("ERROR while loading match list", error);
        extra_data.message = "Please log in to create or join matches";
        clearInterval(reload_match_interval_id);
        return error;
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    if(data.matches){
        match_list.push(...data.matches);
        for(const i in match_list){
            const match = match_list[i];
            match.can_join = !(match.black && match.white);
        }
    }
}

function next_page(){
    extra_data.offset += 20;
    load_match_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_match_list();

}
</script>

<template>
<div class="flex-col">
<div class="flex flex-row-reverse m-2">
    <AccountDetails 
        :user="extra_data.user" 
        :open="extra_data.open_account_details"
        @logged-out="reload()"
    >
    </AccountDetails>
    <router-link :to="{name: 'home'}">
        <button class="btn btn-blue">
           Home 
        </button>
    </router-link>
</div>

<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
<table class="min-w-full divide-y divide-gray-300">
<thead>
    <tr>
        <th scope="col" class="sticky top-0 z-10">White</th> 
        <th scope="col" class="sticky top-0 z-10">Elo</th> 
        <th scope="col" class="sticky top-0 z-10">ER</th> 
        <th scope="col" class="sticky top-0 z-10">Black</th> 
        <th scope="col" class="sticky top-0 z-10">Elo</th> 
        <th scope="col" class="sticky top-0 z-10">ER</th> 
        <th scope="col" class="sticky top-0 z-10">Length</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Time Control</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr>
    <td
        class="w-full text-center"
        colspan=6>
    </td>
    </tr>
    <tr>
    <td v-if="match_list.length == 0"
        class="w-full text-center"
        colspan=6>
        This player has not yet played any matches.
    </td>
    </tr>
    <tr v-for="match in match_list" class="even:bg-gray-50" >
        <td 
            class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-left">
            <span v-if=match.white>
                {{ match.white.username }}
            </span>
            <span v-else=match.white>
                -    
            </span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-center">
            <span v-if=match.white>
                {{ match.white.elo.toFixed(2) }}
            </span>
            <span v-else>
                
            </span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-center">
            <span v-if=match.white>
                {{ match.white.er.toFixed(2) }}
            </span>
            <span v-else>
                
            </span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-left">
            <span v-if=match.black>
                {{ match.black.username }}
            </span>
            <span v-else>
                - 
            </span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-center">
            <span v-if=match.black>
                {{ match.black.elo.toFixed(2) }}
            </span>
            <span v-else>
                
            </span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-center">
            <span v-if=match.black>
                {{ match.black.er.toFixed(2) }}
            </span>
            <span v-else>
                
            </span>
        </td>
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-center">
            {{ match.points }}
        </td>
        <td class="text-center">
            {{ match.status }}
        </td>
        <td class="text-center">
            {{ match.time_control }}
        </td>
        <td class="text-center px-4">
            <router-link :to="{name: 'analysis', params:{match_id: match.match_id}}">
                <button class="btn btn-blue">Analysis</button>
            </router-link>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">Previous</button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">Next</button>
</div>
</div>
</div>
</div>
</div>

<!--
<ul role="list" class="match-list divide-y divide-gray-100 topi mt-4">
    <button class="btn btn-blue mt-4 center" @click="reload_match_list()">Reload Matches</button>
    <div class="match order-first">{{ extra_data.message }}</div>
    <div class="match flex gap-x-4 py-5" v-for="match in match_list">
        {{ match.match.match_id }} - 
        {{ match.match.points }}gt
        
        <span v-if=match.info.white>
            {{ match.info.white.username }}
        </span>
        <span v-if=match.info.white>
            {{ match.info.white.rating }}
        </span> vs 
        <span v-if=match.info.black>
            {{ match.info.black.username }}
        </span>
        <button class="btn btn-blue"
                v-if="match.match.can_join"
                @click="join_match(match.match.match_id)">
            Join
        </button>
    </div>
</ul>
-->

</template>

<style scoped>
</style>
