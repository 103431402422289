
<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'


const extra_data = reactive({
    message: "Welcome join or create a match to start playing.",
    user:{},
    open_account_details:false,
    total_nrof_users:0,
    offset:0,
    status:"active",
});

const status_options = [
    "active", "playing", "finished", "cancelled", "all",
]

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const match_list = reactive([]);
onMounted(() => {
    load_match_list();
});

async function load_match_list(){
    var url = app_server + "/admin/match/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    url += `?status=${extra_data.status}`
    
    console.log(url);
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;    
    }).catch(error => {
        extra_data.message = "Please log in to create or join matches";
        return error;
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    console.log(data);
    match_list.length = 0;// empty the users list
    if(data.matches){
        match_list.push(...data.matches);
        //extra_data.total_nrof_users = data.total_nrof_users;
    }
}

function next_page(){
    extra_data.offset += 200;
    load_match_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 200);
    load_match_list();
}

async function clean_match(match_id){
    const response = await fetch(app_server + `/admin/match/${match_id}/clean/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const request_data = await response.json(); 

    console.log(request_data);
}

async function clean_matches(){
    const response = await fetch(app_server + `/admin/match/clean/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}), 
    });

    const request_data = await response.json(); 

    console.log(request_data);
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
<p>Total matches: {{ extra_data.total_nrof_matches }}</p>
<div>
<button class="btn btn-red" @click="clean_matches()">Clean matches</button>
</div>
<select v-model="extra_data.status" @change="load_match_list">
    <option :value="v" v-for="v in status_options"> {{ v }}</option>
</select>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Match ID</th> 
        <th scope="col" class="sticky top-0 z-10">Create Time</th> 
        <th scope="col" class="sticky top-0 z-10">White</th> 
        <th scope="col" class="sticky top-0 z-10">Black</th> 
        <th scope="col" class="sticky top-0 z-10">Elo W</th> 
        <th scope="col" class="sticky top-0 z-10">Elo B</th> 
        <th scope="col" class="sticky top-0 z-10">Score</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Puzzles</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr v-if="match_list.length == 0">
    <td 
        class="w-full text-center"
        colspan=8>
        No matches 
    </td>
    </tr>
    <tr v-for="match, index in match_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left font-mono" >
            <router-link :to="{name: 'admin-match-detail', params: {match_id: match.match_id}}">
                {{ match.match_id }}
            </router-link>
        </td>
        <td>
            {{(new Date(match.create_time * 1000)).toLocaleString()}}
        </td>
        <td class="text-left"
            :class="{'font-semibold': match.score.W > match.score.B}">
            <span  v-if="match.white">
                {{ match.white.username }}
            </span>
            <span v-else>-</span>
        </td>
        <td class="text-left"
            :class="{'font-semibold': match.score.B > match.score.W}">
            <span  v-if="match.black">
                {{ match.black.username }}
            </span>
            <span v-else>-</span>
        </td>
        <td class="text-center">
            <span  v-if="match.white">
                {{ match.white.elo.toFixed(2) }}
            </span>
            <span v-else>-</span>
        </td>
        <td class="text-center">
            <span  v-if="match.black">
                {{ match.black.elo.toFixed(2) }}
            </span>
            <span v-else>-</span>
        </td>
        <td class="text-right">{{ match.score["W"] }}-{{match.score["B"]}} [{{match.points}}]</td>
        <td class="text-center">{{ match.status }}</td>
        <td class="text-center">{{ match.created_puzzles }}</td>
        <td class="text-center">
            <button class="btn btn-red" @click="clean_match(match.match_id);">
                Clean
            </button>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button :disabled="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
