<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var analyser_id;

const extra_data = reactive({
    analyser: {},
    api_key: null,
});


onMounted(() => {
    analyser_id = useRoute().params.analyser_id;
    load_analyser();
});

async function save_analyser(){
    var url = app_server + `/admin/analyser/${analyser_id}/`;
    const data = extra_data.analyser;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const analyser_data = await response.json();
    if(analyser_data.analyser){
        extra_data.analyser = analyser_data.analyser;
    }
    console.log(analyser_data);
}

async function load_analyser(){
    var url = app_server + `/admin/analyser/${analyser_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log(data);
    if(data.analyser){
        extra_data.analyser = data.analyser;
    }
}

async function create_apikey(){
    var url = app_server + `/admin/analyser/${analyser_id}/apikey/`;
    const data = {};

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const analyser_data = await response.json();
    if(analyser_data.api_key){
        extra_data.api_key = analyser_data.api_key;
    }
    console.log(analyser_data);
}
</script>

<template>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-analysers'}">
            Analyser List
        </router-link>
    </button>
    <button class="btn btn-blue"
        @click="create_apikey()"
    >
        Create Api Key
    </button>
    <p v-if="extra_data.api_key"> API KEY: {{ extra_data.api_key }} </p>
    <h1>Analyser Detail</h1>
    <button @click="save_analyser()" class="btn btn-blue">Save</button>
    <form>
        <div v-for="value,field in extra_data.analyser">
            <label :for="field"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                {{ field }}
            </label>
            
            <textarea 
                v-model="extra_data.analyser[field]"
                v-if="field == 'description'"
                :id="field"
            ></textarea>
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="extra_data.analyser[field]"
                v-else
                :id="field"
            />
        </div>
    </form>
</template>

<style>
</style>

