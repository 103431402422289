<script setup>
import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import Board from './Board.vue'
import Message from './Message.vue'
import Header from './Header.vue'
import GameSidebar from './GameSidebar.vue'

import { Match } from '../assets/js/match.js'

const app_server = import.meta.env.VITE_APP_SERVER;

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore, useMatchSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();
const matchStore = useMatchSSEStore();

const route = useRoute();
const router = useRouter();

const state = reactive({
    currentState: null,
    board_message: "",
    match_info: {},
    clock_data: {},
});

var match_id = null;
const match = reactive(new Match());

onMounted(async () => {
    await userStore.loadUser();

    userStore.loadPreferences().then( () => {
        userStore.applyBoardPreferences(document.getElementById("game"))
    });
    
    match_id = route.params.match_id;
    sseStore.connect();

    matchStore.channel = `${userStore.info.user_id}/spectate/${match_id}`;
    matchStore.connect();
    
    matchStore.addListener("move", (m) => update_move(m));
    matchStore.addListener("clock", (m) => update_clock(m));
    matchStore.addListener("finalize", (m) => match_finished(m));

    get_match_info(match_id);
});

onUnmounted(() => {
    matchStore.disconnect();
    // Remove the listeners
    matchStore.addListener("move", (m) => {});
    matchStore.addListener("clock", (m) => {});
    matchStore.addListener("finalize", (m) => {});
});

async function get_match_info(match_id){
    const response = await fetch(app_server + `/match/${ match_id }/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const match_data = await response.json();
    // match.from_json(match_data.match);
    console.log(match_data);
    state.match_info = match_data.info;
    state.currentState = match_data.last_state;
}

function update_move(message){
    console.log(message.state);
    state.currentState = message.state;
    if(message.clock_config){
        state.clock_data.clock_config = message.clock_config;
    }
}

function update_clock(message){
    state.clock_data.clock = message.clock;
    if(message.clock_config){
        state.clock_data.clock_config = message.clock_config;
    }
}

function match_finished(message){
    router.push({name:"home"});
}

</script>
<template>
<Header />
<Message />
<div id="game" 
    class="game flex flex-col sm:flex-row relative"
>
    <Board :positionString="state.currentState" 
           :player_color="match.player.color" 
           :score="state.score"
           :clock_data="state.clock_data"
           :board_message="state.board_message"
           :match_info="state.match_info"
           :show_chat="false"
           :show_resign="false"
           :direction="'CW'"
    />
    <GameSidebar 
           :player_color="match.player.color" 
           :match_info="state.match_info"
           :show_chat="false"
    />
</div>
</template>

<style scoped>
.game{
    height: var(--h-rest);
    width: 100svw;
    max-width: 100%;
    max-height: 100%;
    background: var(--case-color);
    background: linear-gradient(0.25turn, var(--case-med-color), var(--case-color), var(--case-med-color));
    color: var(--text-color);
    overflow: hidden;
}
.overview{
    display:none;
    width:30em;
    overflow-y:auto;
    font-family: monospace;
}
</style>
