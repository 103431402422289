<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const props = defineProps({
    direction: { // Used if multiple boards are shown in the same view
        type: String,
        default: "top",
    },
    text: { // Used if multiple boards are shown in the same view
        type: String,
        default: "",
    },
});

</script>
<template>
<div>
    <div class="group/tooltip relative">
        <div class="invisible group-hover/tooltip:visible group-hover/tooltip:transition-all transition-none delay-500 absolute
                    bg-case-color opacity-80 text-case-light-color p-2 shadow-xl
                    rounded tooltip text-sm text-center max-w-64 w-40 z-10 flex justify-center"
            :class="[direction]"
            v-if="props.text.length > 0"
        >
            {{ props.text }}
        </div>
        <slot></slot>
    </div>
</div>
</template>

<style scoped>
.top {
    position:absolute;
    top: -10px;
    left: 50%;
    transform: translateY(-100%) translateX(-50%); 
}
.bottom {
    position:absolute;
    bottom: -10px;
    left:50%;
    transform: translateY(100%) translateX(-50%); 
}
.left {
    position:absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%) translateX(-100%); 
}
.right {
    position:absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%) translateX(100%); 
}
.tooltip:after {
    content: "";
    position: absolute;
    border: 10px solid var(--case-color);
    pointer-events: none;
}
.tooltip.bottom:after {
    border-color: transparent transparent var(--case-color) transparent;
    left:calc(50% - 10px);
    bottom:100%;
}
.tooltip.top:after {
    border-color: var(--case-color) transparent transparent transparent;
    left:calc(50% - 10px);
    top:100%;
}
.tooltip.left:after {
    border-color: transparent transparent transparent var(--case-color);
    left:100%;
    top:calc(50% - 10px);
}
.tooltip.right:after {
    border-color: transparent var(--case-color) transparent transparent;
    right:100%;
    top:calc(50% - 10px);
}
</style>

