<script setup>

import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {SparklesIcon, UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import Header from './Header.vue'
import ToolTip from './Tooltip.vue'
import Avatar from './Avatar.vue'
import MatchList from './MatchList.vue'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import { Line, Radar } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

const chart_options = {
    responsive: true,
    maintainAspectRatio: false,
    scales:{
        x:{
            ticks:{
                display: false,
            },
        },
    },
    plugins:{
        legend: {display: false},
    },
};

const extra_data = reactive({
    rating_list: [],
    charts: {},
    elo_list: [],
    er_table: [], 
    user_info: {},
    user1: {},
    user2: {},
    logged_in_user: {},
    user_id: null,
    show_er: 0,
});

onMounted(async () => {
    extra_data.user1_id = route.params.user1_id;
    extra_data.user2_id = route.params.user2_id;
    await userStore.loadUser();

    retrieve_data();
});

watch(() => route.fullPath, async () => {
    extra_data.user1_id = route.params.user1_id;
    extra_data.user2_id = route.params.user2_id;
    await get_user_info(extra_data.user1_id);
    await get_user_info(extra_data.user2_id);
});

async function retrieve_data(){
    await Promise.allSettled([
        extra_data.user1 = await get_user_info(extra_data.user1_id),
        extra_data.user2 = await get_user_info(extra_data.user2_id),
        get_insights(),
    ]);

    extra_data.matches = await get_vs_matches({});
    get_er_table();
    // extra_data.charts = get_charts();
}

async function get_user_info(user_id){
    const response = await fetch(app_server + `/user/${user_id}/info/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_info = await response.json();
    
    user_info.user.date_joined = new Date(user_info.user.date_joined);
    return user_info.user; 
}

async function get_vs_matches(options){
    const url = `/user/${extra_data.user1_id}/vs/${extra_data.user2_id}/?${new URLSearchParams(options).toString()}`;
    const response = await fetch(app_server + url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_matches = await response.json();
    
    for(let match of user_matches.matches){
        match.score = JSON.parse(match.score);
    }
    console.log(user_matches);    
    return user_matches.matches;
}

function get_er_table(){
    // Use the matches to compute the ER's
    const match_length_er_1 = {};
    const match_length_er_2 = {};
    let user1_er, user2_er;
    for(let match of extra_data.matches){
        if(extra_data.user1_id == match.white.user_id){
            user1_er = match.white.er;
            user2_er = match.black.er;
        }else{
            user1_er = match.black.er;
            user2_er = match.white.er;
        }
        console.log(match_length_er_1,match_length_er_2, user1_er, user2_er, (match_length_er_1[match.points] || []));
        if(match_length_er_1[match.points] == null){
            match_length_er_1[match.points] = [];
        }
        if(match_length_er_2[match.points] == null){
            match_length_er_2[match.points] = [];
        }
        match_length_er_1[match.points].push(user1_er);
        match_length_er_2[match.points].push(user2_er);
    }

    extra_data.er_table.length = 0;
    const match_lengths = Object.keys(match_length_er_1).map( (x) => parseInt(x));
    console.log(match_lengths);
    let avg_er_1, avg_er_2, N_er_1, N_er_2;
    for(let l of match_lengths){
        N_er_1 = (match_length_er_1[l] || [0]).length
        N_er_2 = (match_length_er_2[l] || [0]).length
        avg_er_1 = (match_length_er_1[l] || [0]).reduce( (partial, a) => partial + a, 0) / N_er_1;
        avg_er_2 = (match_length_er_2[l] || [0]).reduce( (partial, a) => partial + a, 0) / N_er_2;

        extra_data.er_table.push( [l,  [avg_er_1, N_er_1], [avg_er_2, N_er_2]]);
    }
}

function get_wins_table(){
    const wins_table_cells = [];
    for(let match of extra_data.matches){
    }
}

async function get_insights(){
    return // We would like to see insights, but we need to implement this.
}

function get_match_charts(matches){
    return;

    const charts = {
        elo: [],
        rating: [],
        er: [],
        coins: [],
    };
    const chart_labels = [];
    
    for(let match of matches.toReversed()){
        let player = null;
        if(match.white.user_id == extra_data.user1_id){
            player =  match.white;
        }else if(match.black.user_id == extra_data.user_id){
            player =  match.black;
        }else{
            continue;
        }
        
        charts["er"].push(player.er);
        chart_labels.push(match.create_time);
    }
    return [charts, chart_labels];
}

function to_readable_date(d){
    const month_nr_to_name = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Sep", "Oct", "Nov", "Dec"];
    return `${month_nr_to_name[d.getMonth()]}, ${d.getFullYear()}`
}

function get_chart_data(chart_type){
    const style = getComputedStyle(document.body);
    const field_color = style.getPropertyValue('--field-color');

    if(!extra_data.charts[chart_type]){
        return;
    }

    var [chart, labels] = extra_data.charts[chart_type]
    
    labels = labels.map(x => new Date(x*1000).toLocaleString());

    const chart_data = {
        labels: labels,
        datasets: [
            {
                label: chart_type,
                data: chart,
                borderColor: field_color,
            },
        ],
    }

    return chart_data;
}

function get_er_table_cells(variable_to_show=0){
    if(extra_data.er_table == null){
        return [];
    }
    const er_table = extra_data.er_table;
    const cells = [];
    for(let [match_length, vars_1, vars_2] of er_table){
        if(match_length == 0){
            cells.push("sparkle");
        }else if(Number.isInteger(match_length)){
            cells.push(match_length);
        }else if(Array.isArray(match_length)){
            var m = "";
            if(Number.isInteger(match_length[0])){
                m += match_length[0];
            }else{
                m += "<";
            }
            if(Number.isInteger(match_length[1])){
                m += match_length[1];
            }else{
                m = ">" + m;
            }
            
            if(m == "><"){
                m = "Any";
            }
            cells.push(m)
        }

        let variable_1 = vars_1[variable_to_show % vars_1.length];
        let variable_2 = vars_2[variable_to_show % vars_2.length];
            
        if(variable_1 == null || variable_1 >= 100){
            cells.push("-");
        }else{
            cells.push(variable_1.toFixed( (variable_1 < 10) ? 2 : 1));
        }
        if(variable_2 == null || variable_2 >= 100){
            cells.push("-");
        }else{
            cells.push(variable_2.toFixed( (variable_2 < 10) ? 2 : 1));
        }
    }
    return cells;
}
</script>

<template>
    <div class="container mx-auto">
    <Header />
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="p-4 rounded-md bg-case-light-color flex relative">
            <div v-if="extra_data.user1.user_id" class="grow">
                <h3 class="text-lg font-medium">{{ extra_data.user1.username }}</h3>
                
                <p class="font-thin">{{ to_readable_date(extra_data.user1.date_joined) }}</p>
                <p class="font-thin">{{ extra_data.user1.level[2] }}</p>
                
            </div>
            <div class="w-16 h-16">
                <router-link :to="{name:'user-avatar'}">
                    <Avatar :avatar_id="extra_data.user1.avatar"/>
                </router-link>
            </div>
        </div>
        <div class="p-4 rounded-md bg-case-light-color flex relative">
            <div v-if="extra_data.user2.user_id" class="grow">
                <h3 class="text-lg font-medium">{{ extra_data.user2.username }}</h3>
                
                <p class="font-thin">{{ to_readable_date(extra_data.user2.date_joined) }}</p>
                <p class="font-thin">{{ extra_data.user2.level[2] }}</p>
                
            </div>
            <div class="w-16 h-16">
                <router-link :to="{name:'user-avatar'}">
                    <Avatar :avatar_id="extra_data.user2.avatar"/>
                </router-link>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.er_table">
                <span class="flex justify-between">
                    <h3 class="text-lg font-medium">Average ER 
                        <span v-if="extra_data.show_er == 1">(N)</span>
                    </h3>
                </span>
                <div @click="extra_data.show_er = (extra_data.show_er + 1) % 2" 
                    class="gap-y-1 gap-x-0.5 bg-field-color
                    grid grid-flow-col grid-rows-3 auto-cols-fr align-items-stretch justify-items-stretch">
                    <div class="bg-case-light-color text-center p-2 tabular-nums" 
                        v-for="cell in get_er_table_cells(extra_data.show_er)">
                        <SparklesIcon v-if="cell == 'sparkle'" class="w-6 h-6 inline-block"/>
                        <span v-else>{{ cell }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.insights">
                <span class="flex justify-between">
                    <h3 class="text-lg font-medium">Insights</h3>
                    <ToolTip :text="'Start a quiz built around this player\'s 3 worst categories.'">
                    <button class="btn btn-blue" @click="create_quiz_for_user()">
                        Quiz
                    </button>
                    </ToolTip>
                </span>
                <table class="w-full tabular-nums">
                    <tr>
                        <th class="text-left">Tag</th>
                        <th>ER</th>
                        <th>EQ</th>
                        <th>Count</th>
                    </tr>
                    <tr v-for="value in extra_data.insights.data">
                        <td>
                            <ToolTip :text="extra_data.insights.explanation[value[0]]" :direction="'top'">
                            {{ value[0] }}
                            </ToolTip>
                        </td>
                        <td class="text-right">{{ value[1].ER.toFixed(2) }}</td>
                        <td class="text-right">{{ value[1].eq_lost.toFixed(2) }}</td>
                        <td class="text-right">{{ value[1].count }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.charts.er">
                <h3 class="text-lg font-medium">
                    ER: {{ extra_data.user.avg_er.toFixed(2) }} ({{extra_data.user.median_er.toFixed(2)}})
                </h3>
                <div class="relative w-full h-full">
                    <Line :data="get_chart_data('er')" :options="chart_options" />
                </div>
            </div>
        </div>

        <div class="p-4 rounded-md bg-case-light-color">
            <div v-if="extra_data.matches">
                <h3 class="text-lg font-medium">Latest Matches</h3>
                <div class="max-h-96 overflow-y-auto">
                    <MatchList :matches='extra_data.matches'/>
                </div>
            </div>
        </div>

    </div>
    </div>
</template>

<style>
</style>

