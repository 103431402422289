<script setup>
import {FlagIcon} from '@heroicons/vue/24/solid'

const props = defineProps({
    score:{
        type: Object,
    },
    crawford:{
        type: Boolean,
    },
    player_color:{
        type: String,
    },
    color:{
        type:String,
    }
})

const emit = defineEmits([
    'resign'
]);

function get_score(){
    return props.score[props.color];
}

</script>

<template>
    <div class="text-board-text-color flex flex-col items-center justify-center" >
        <p 
            class="text-[0.5rem] @md:text-sm font-thin text-center w-full justify-center">
            <span v-if="score.crawford">
                Crawford 
            </span>
            <span v-else-if="score.last_game">
                Last game
            </span>
            <span v-else>
                Score 
            </span>
        </p>
        <span class="@md:text-lg @lg:text-2xl">{{ get_score() }}</span>
        <p v-if="score.match_length > 0"
            class="text-[0.5rem] @md:text-sm font-thin text-center whitespace-nowrap">
            Until {{ score.match_length }}
        </p>
        <p  v-else
            class="text-[0.5rem] @md:text-sm font-thin text-center whitespace-nowrap">
            Unlimited
        </p>
    </div>
</template>

<style scoped>
</style>
