<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import {ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'
import {BoardState} from '../assets/js/board.js'

import Board from './Board.vue'
import Tooltip from './Tooltip.vue'

// const app_server = import.meta.env.VITE_APP_SERVER;
// const router = useRouter();

const props = defineProps({
    sequences: {
        type: Array,
        default: [],
    },
    boardID: {
        type: String,
        default: "",
    },
});

const extra_data = reactive({
    current_line: [],
    current_action: 0,
    current_state: null,

    current_arrows: [],
});


onMounted(() => {
    const board = new BoardState();
    if(props.sequences.length > 0){
        extra_data.current_line = props.sequences[0];
    }else{
        extra_data.current_line = [board.default_position];
        console.log(extra_data.current_line);
    }

    const first_state = extra_data.current_line.filter( (x) => x.type == "state")[0]
    if(first_state == null){
        extra_data.current_state = board.default_position;
    }else{
        extra_data.current_state = first_state.data;
    }
});

function select_action(sequence, action){
    console.log(action);
    extra_data.current_state = action.state.toPositionString();
    extra_data.current_arrows = [];
    extra_data.current_action = action.action_id;
    console.log(extra_data.current_action);

    if(action.type == "move"){
        console.log(action);
        for(let m of action.data){
            console.log(m);
            extra_data.current_arrows.push( {start: m[0], end: m[1]} );
        }
    }
}

function get_analysis_class(analysis){
    const analysis_class_dict = {
        "": ["hidden"],
        "?!": ["dubious"],
        "!?": ["dubious"],
        "?": ["error"],
        "??": ["blunder"],
        "!": ["important"],
        "!!": ["brilliant"],
    }
    return analysis_class_dict[analysis];
}

</script>

<template>
    <div :id="'boardseq-'+props.boardID" 
        class="w-full flex flex-col md:flex-row gap-x-4">
        <div class="grow">
            <Board 
                :positionString="extra_data.current_state"
                :arrows="extra_data.current_arrows"
                :show_pip_numbers="true"
                :player_color="'W'"
                :boardID="boardID"
            /> 
        </div>
        <div class="w-full md:w-1/3 flex flex-col gap-y-4">
            <div v-for="sequence in props.sequences">
                <div v-for="action in sequence" 
                    @click="select_action(sequence, action)"
                >
                    <div v-if="action.type == 'line'" 
                        class="border-l-4 border-field-color mb-4"
                    >
                        <div v-for="a in action.data" 
                            @click.stop="select_action(sequence, a)"
                        >
                            <Tooltip :text="a.tooltip">
                            <div
                                class="flex items-center gap-x-1 cursor-pointer hover:bg-field-light-color ml-2"
                                >
                            <span class="rounded-full border-case-color border w-5 h-5 inline-block" 
                                :class="{
                                    'bg-stone-w-color': a.color == 'W', 
                                    'bg-stone-b-color': a.color == 'B'
                                }"
                                  />
                            <span :class="{'font-semibold': a.action_id == extra_data.current_action}">
                                {{ a.text }}
                            </span>
                            <span class="rounded-full border-case-color border w-5 h-5 inline-flex justify-center items-center ml-2" 
                                :class="get_analysis_class(a.analysis)"/>
                            </div>
                            </Tooltip>
                        </div>
                    </div>
                    <Tooltip v-else-if="action.type == 'move'" 
                             :text="action.tooltip"
                    >
                        <div 
                             class="flex items-center gap-x-1 cursor-pointer hover:bg-field-light-color"
                        >
                        <div class="rounded-full border-case-color border w-5 h-5 inline-block" 
                            :class="{
                                'bg-stone-w-color': action.color == 'W', 
                                'bg-stone-b-color': action.color == 'B'
                            }"
                        />
                        <span :class="{'font-semibold': action.action_id == extra_data.current_action}">
                            {{ action.text }}
                        </span>
                        <span class="rounded-full border-case-color border w-5 h-5 inline-flex justify-center items-center ml-2" 
                            :class="get_analysis_class(action.analysis)"/>
                        </div>
                    </Tooltip>
                    <div v-else-if="action.type == 'state'" class="text-lg font-semibold">
                        <span>{{ action.text }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.error{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--error-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--error-color));
    background-color: var(--error-color);
}
.error::after {
    content: "?";
}

.blunder{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--blunder-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--blunder-color));
    background-color: var(--blunder-color);
    letter-spacing: -.1em;
}
.blunder::after {
    transform: translate(-.03em);
    content: "??";
}

.brilliant{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--brilliant-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--brilliant-color));
    background-color: var(--brilliant-color);
}
.brilliant::after {
    content: "!!";
}

.critical{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--critical-color));
    color: #fff;
    color: color-mix(in srgb,white 80%,var(--critical-color));
    background-color: var(--critical-color);
}
.critical::after {
    content: "!!";
}
.dubious{
    border: solid .15em color-mix(in srgb,var(--case-color) 30%,var(--error-color));
    color: #fff;
    color: color-mix(in srgb, var(--case-color) 20%,var(--error-color));
    background-color: color-mix(in srgb, white 50%, var(--error-color));
}
.dubious::after {
    content: "?!";
}

.ok{
    display:none;
}
</style>

