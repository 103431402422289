<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'

const props = defineProps({
    tag: { // Used if multiple boards are shown in the same view
        type: String,
        default: "",
    },
});

const tag_data = reactive({
    tag_id: "",
    color: "",
});

watch(props, () =>{

    update_tag();
});

function update_tag(){
    const tag = props.tag.split(":");

    if(tag.length == 2){
        tag_data.tag_id = tag[0];
        tag_data.color = tag[1];
    }else{
        tag_data.tag_id = "";
        tag_data.color = "";
    }
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        // throw new Error('Invalid HEX color.');
        return hex;
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if(bw){
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 150
            ? '#333333'
            : '#F2F2F2';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);

    // pad each with zeros and return
    const color_str = "#" + padZero(r) + padZero(g) + padZero(b);
    return color_str;
}

onMounted(async () => {
    update_tag();
});

</script>
<template>
    <div v-if="tag_data.tag_id.length > 0" 
        class="px-1.5 rounded-2xl text-xs inline-block" 
        :style="{'background-color': tag_data.color, 
                }"
    >
        <span class="font-semibold"
            :style="{'color': invertColor(tag_data.color, true)}"
        >
            {{ tag_data.tag_id }}
        </span>
    </div>
</template>

<style>
</style>

