<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'


const extra_data = reactive({
    message: "Welcome join or create a match to start playing.",
    user:{},
    total_nrof_solves:0,
    offset:0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

var user_id;

const solve_list = reactive([]);
onMounted(() => {
    user_id = route.params.user_id;
    load_solve_list();
});

async function load_solve_list(){
    var url = app_server + `/admin/user/${user_id}/solve/`;
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;    
    }).catch(error => {
        return error;
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    console.log(data);
    solve_list.length = 0;// empty the users list
    if(data.solves){
        solve_list.push(...data.solves);
        extra_data.total_nrof_solves = data.total_nrof_solves;
    }
}

function next_page(){
    extra_data.offset += 100;
    load_solve_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 100);
    load_solve_list();
}

async function revert_solve(solve_pk){
    if(!window.confirm(`Are you sure you want to revert this solution`)){
        return;
    }
    const response = await fetch(app_server + `/admin/puzzle/solve/${solve_pk}/revert`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
        }),
    });
    const data = await response.json();
    console.log(data);
}

</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
<p>Total solves: {{ extra_data.total_nrof_solves }}</p>
<p>Offset: {{ extra_data.offset }}</p>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Puzzle</th> 
        <th scope="col" class="sticky top-0 z-10">EQ diff</th> 
        <th scope="col" class="sticky top-0 z-10">Rating diff</th> 
        <th scope="col" class="sticky top-0 z-10">Solve Time</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="solve_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No solves
    </td>
    </tr>
    <tr v-for="solve, index in solve_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        
        <td class="text-left">{{ solve.puzzle_id }}</td>
        <td class="text-right">{{ solve.eq_difference.toFixed(3) }}</td>
        <td class="text-right">{{ solve.user_rating_diff.toFixed(2) }}</td>
        <td class="text-right">{{ (new Date(solve.solution_time * 1000)).toISOString() }}</td>
        <td class="text-right">
            <button class="btn btn-blue mx-4" @click="revert_solve(solve.pk)">
                Revert
            </button>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
