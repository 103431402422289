<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var theme_id;

const extra_data = reactive({
    theme: {}
});


onMounted(() => {
    theme_id = useRoute().params.theme_id;
    load_theme();
});

async function save_theme(){
    var url = app_server + `/admin/theme/${theme_id}/`;
    const data = extra_data.theme;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const theme_data = await response.json();
    if(theme_data.theme){
        extra_data.theme = theme_data.theme;
    }
    console.log(theme_data);
}

async function load_theme(){
    var url = app_server + `/admin/theme/${theme_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log(data);
    if(data.theme){
        extra_data.theme = data.theme;
    }
}

</script>

<template>
    <h1>Theme Detail</h1>
    <button @click="save_theme()" class="btn btn-blue">Save</button>
    <form>
        <div v-for="value,field in extra_data.theme">
            <label :for="field"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                {{ field }}
            </label>
            
            <textarea 
                v-model="extra_data.theme[field]"
                v-if="field == 'description'"
                :id="field"
            ></textarea>
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="extra_data.theme[field]"
                v-else
                :id="field"
            />
        </div>
    </form>
</template>

<style>
</style>

