<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var user_id;

const extra_data = reactive({
    user: {},
    permissions: "",
    api_key: "",
});

const set_achievement_data = reactive({
    "achievement_key": "",
    "value": 0,
});

const notify_data = reactive({
    "message": "",
});

onMounted(() => {
    user_id = useRoute().params.user_id;
    load_user();
    get_permissions();
});

async function save_user(){
    var url = app_server + `/admin/user/${user_id}/`;
    const data = extra_data.user;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const user_data = await response.json();
    if(user_data.user){
        extra_data.user = user_data.user;
    }
}
async function set_achievement(){
    var url = app_server + `/admin/user/${user_id}/setachievement/`;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(set_achievement_data),
    });
    
    const user_data = await response.json();
    console.log(user_data);
}
async function notify(){
    var url = app_server + `/admin/user/${user_id}/notify/`;
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(notify_data),
    });
    
    const user_data = await response.json();
    console.log(user_data);
}
async function load_user(){
    var url = app_server + `/admin/user/${user_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    if(data.user){
        extra_data.user = data.user;
    }
}

async function save_permissions(){
    var url = app_server + `/admin/user/${user_id}/permissions/`;
    const data = { "permissions": JSON.parse(extra_data.permissions)};

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const user_data = await response.json();
    if(user_data.permissions){
        extra_data.permissions = JSON.stringify(user_data.permissions, null, 2);
    }
}

async function get_permissions(){
    var url = app_server + `/admin/user/${user_id}/permissions/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const user_data = await response.json();
    extra_data.permissions = JSON.stringify(user_data.permissions, null, 2);
    console.log(user_data);
}
async function get_apikey(){
    var url = app_server + `/admin/user/${user_id}/getapikey/`;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const user_data = await response.json();
    console.log(user_data);
    extra_data.api_key = user_data;
}
</script>

<template>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-users'}">
            User List
        </router-link>
    </button>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-user-solvelist', param:{user_id: user_id}}">
            Solve List
        </router-link>
    </button>
    <button class="btn btn-blue"
        @click="get_apikey()"
    >
        Get API key
    </button>
    {{ extra_data.api_key }}
    <h1>User Detail</h1>
    <button @click="save_user()" class="btn btn-blue">Save</button>
    <form>
        <div v-for="value,field in extra_data.user">
            <label :for="field"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                {{ field }}
            </label>
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="extra_data.user[field]" :id="field"/>
        </div>
    </form>
    <h1 class="py-8 text-2xl">Achievement</h1>
    <input type="text" v-model="set_achievement_data.achievement_key" placeholder="achievement key">
    <input type="number" v-model="set_achievement_data.value" placeholder="0">
    <button @click="set_achievement()" class="btn btn-blue">Set Achievement</button>

    <h1 class="py-8 text-2xl">Permissions</h1>
    <div class="mb-16">
        <p><textarea class="w-96 h-96" v-model="extra_data.permissions"></textarea></p>
        <button @click="save_permissions()" class="btn btn-blue">Set Permissions</button>
    </div>

    <h1 class="py-8 text-2xl">Notify</h1>
    <input type="text" v-model="notify_data.message" placeholder="Message to send">
    <button @click="notify()" class="btn btn-blue">Send notification</button>
</template>

<style>
</style>
