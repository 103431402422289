<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const props = defineProps({
    avatar_id: { // Used if multiple boards are shown in the same view
        type: String,
        default: "",
    },
});

const avatar_data = reactive({
    avatar_color_map : {
        "0" : "#FFFFFF00",
        "1" : "#000000",
    },
    avatar_border_colors : {
        "0" : "0",
        "1" : "1",
    },
});


function get_checker_style(color_id){
    const color = avatar_data.avatar_color_map[color_id];
    const border_id = avatar_data.avatar_border_colors[color_id];
    const border_color = avatar_data.avatar_color_map[border_id];
    const color_style = {
        "background-color": color, 
        "border-color": border_color,
    };
    return color_style;
}

onMounted(async () => {
    await userStore.loadAchievements();
    avatar_data.avatar_color_map = userStore.achievements.color_keys;
    avatar_data.avatar_border_colors = userStore.achievements.border_colors;
});

</script>
<template>
    <div v-if="props.avatar_id" 
        class="grid grid-cols-5 aspect-square w-full max-h-full">
        <div v-for="c, index in props.avatar_id" 
            class="rounded-full border"
            :style="get_checker_style(c)"
        >
        </div>
    </div>
</template>

<style>
</style>
