/*
 * Encodes the state maching of boards
 *
 * IW -> IB -> ... -> IB
 *
 * The state machine takes a state and computes:
 *  - If it is the players turn
 *  - takes care of the dice rolling
 * 
 * The gamestate in a state signifies the state after the action, thus
 * if we see a gamestate C (checker) it means we did a checker move and ended up 
 * in this gamestate.
 *
 * IW: Initial white, white rolled the initial dice
 * IB: Initial black, black rolled the initial dice
 * PR: The color rolled the first set of dice
 * R : The color rollled the second set of dice and combined them with the first
 * C : The color played a checker move
 * D : The color doubled
 * A : The color accepted
 * P : The color passed
 * G : The color won the game
 * M : The color won the match
 * RG: The color resigned the game
 * RM: The color resigned the match
 *
 * When we receive a state we know exactly what state we are in
 */ 

import { BoardState } from './board.js'

export class StateMachine{
    constructor(roll_dice_callback, player_color, limit=null){
        /*
         * roll_dice_callback takes as input:
         *  - current_dice
         *  - move_id
         *  - distinct
         *
         * and returns the combined dice [1-6, 1-6]. If distinct is true, the dice
         * cannot be the same (no doubles).
         *
         */
        this.roll_dice_callback = roll_dice_callback;
        this.player_color = player_color
        this.initial_board = null;
        this.limit = limit;
    }

    next_state(board, action=null){
        /* 
         * Given a state of the board we compute the next state (if we should)
         */

        if(board.game_state == "IW" && board.dice.length == 0){
            return this.handle_setup(board);

        }else if(board.game_state == "IW"){
            return this.handle_IW(board);
        }else if(board.game_state == "RM" || board.game_state == "RG" || board.game_state == "FF"){
            return this.handle_resign(board, action);
        } 

        //  is the opponents turn, so don't compute the next state
        if(board.color == this.player_color){
            return null;
        }

        // states where the next state is computed by user_interaction are
        // computed seperately
        var new_board;
        if(board.game_state == "IB" || board.game_state == "R"){
            new_board = this.next_after_roll(board, action);
        }else if(board.game_state == "C" || board.game_state == "A"){
            new_board = this.next_after_checker(board, action);
        }else if(board.game_state == "D"){
            new_board = this.next_after_double(board, action);
        }else if(board.game_state == "P"){
            new_board = this.handle_P(board);
        }else if(board.game_state == "PR"){
            new_board = this.handle_PR(board);
        }else if(board.game_state == "G"){
            new_board = this.handle_G(board);
        }else if(board.game_state == "M"){
            new_board = this.handle_M(board);
        }else if(board.game_state == "F"){
            new_board = board.copy();
            new_board.color = this.player_color;
        }else{
            console.log("WARNING: No game state handler", board.game_state);
        }

        return new_board;
    }

    next_after_roll(old_board, action=null){
        /*
         * This handles IB and R states, the user has to pick a new checker state
         *
         * action contains the new state after the checker moves 
         */
        if(!(old_board.color != this.player_color && ["R", "IB"].includes(old_board.game_state))){
            // we cannot do a checker move
            return null;
        }
        if(action == null){
            return null;
        }
        var new_board = action.copy();
        new_board.score = old_board.score;
        new_board.color = this.player_color;
        new_board.cube = old_board.cube;
        new_board.game_state = "C";
        new_board.dice = [];

        new_board = this.apply_win(new_board);

        return new_board;
    }

    next_after_checker(old_board, action){
        /*
         * This handles C states, the user has to pick between rolling and doubling.
         *
         * This method also checks if the user can cube
         *
         * action can be "roll" or "double"
         */
        if(!(old_board.color != this.player_color && ["C", "A"].includes(old_board.game_state))){
            return null;
        }

        if(this.can_double(old_board) && action == null){
            return null;
        }
        const new_board = old_board.copy();
        new_board.color = this.player_color;
        new_board.dice = [];
        /* check the cube for the next state */
        if(this.can_double(old_board) && action == "double"){
            // We cube
            new_board.cube.action = "O";
            new_board.game_state = "D";
            new_board.move_id += 1; // Doubling starts a new move
        }else{
            // we roll
            this.roll_dice(new_board);
        }

        return new_board;
    }

    next_after_double(old_board, action){
        /* This handles the D state (double), the user has to pick between take or pass */
        if(!(old_board.color != this.player_color && old_board.game_state == "D")){
            // we cannot do a checker move
            return null;
        }
        if(action == null){
            return null;
        }
        
        const new_board = old_board.copy();
        new_board.color = this.player_color;
        new_board.dice = [];

        if(action == "take"){
            new_board.cube.owner = this.player_color;
            new_board.cube.action = "T";
            new_board.game_state = "A"; 
            new_board.cube.value = old_board.cube.value + 1;
        }else{
            new_board.cube.owner = "N";
            new_board.cube.action = "P";
            new_board.game_state = "P"; 
        } 
        new_board.move_id += 1; // taking or passing starts a new move

        return new_board;
    }
    
    handle_setup(old_board){
        if(this.player_color != "W"){
            return null;
        }

        const new_board = old_board.copy();
        new_board.dice = this.roll_dice_callback([], old_board.move_id, false);
        new_board.game_state = "IW";
        new_board.color = "W";

        return new_board;
    }

    handle_IW(old_board){
        if(this.player_color != "B"){
            return null;
        }
        const new_board = old_board.copy();
        new_board.dice = this.roll_dice_callback(old_board.dice, old_board.move_id, true);
        new_board.game_state = "IB";
        if(new_board.dice[0] < new_board.dice[1]){
            new_board.color = "B";
        }else{
            new_board.color = "W";
        }
        return new_board;
    }
    
    handle_PR(old_board){
        if(!(old_board.color != this.player_color && ["PR"].includes(old_board.game_state))){
            return null;
        }
        const new_board = old_board.copy();
        new_board.dice = this.roll_dice_callback(old_board.dice, old_board.move_id, false);
        new_board.game_state = "R";
        new_board.color = this.player_color;
        return new_board;
    }

    handle_P(old_board){
        if(!(old_board.color != this.player_color && old_board.game_state == "P")){
            // we cannot do a checker move
            return null;
        }
        var new_board = old_board.copy(); 
        new_board.color = this.player_color;
        new_board.dice = [];

        new_board = this.apply_win(new_board);

        // Reset the cube
        var initial_board = this.initial_board;
        if(this.initial_board == null){
            initial_board = new BoardState(old_board.default_position);
        }
        new_board.cube = {...initial_board.cube}

        new_board.move_id += 1;

        return new_board;
    }

    handle_M(old_board){
        if(!(old_board.color != this.player_color && old_board.game_state == "M")){
            // we cannot do a checker move
            return null;
        }
        const new_board = old_board.copy(); 
        new_board.game_state = "F";
        new_board.color = this.player_color;

        return new_board;
    }

    handle_G(old_board){
        if(!(old_board.color != this.player_color && old_board.game_state == "G")){
            // we cannot do a checker move
            return null;
        }

        if(this.initial_board == null){
            this.initial_board = new BoardState(old_board.default_position);
        }
        const new_board = this.initial_board.copy();
        console.log("Resetting board to:", new_board.toPositionString());
        new_board.score = old_board.score;
        new_board.is_crawford = old_board.is_crawford;
        new_board.match_length = old_board.match_length;
        new_board.move_id = old_board.move_id + 1;
        console.log("NEW BOARD:", new_board);

        return new_board;
    }

    handle_resign(old_board, action){
        if(old_board.color == this.player_color){ 
            // The current player can resign
            const new_board = old_board.copy(); 
            if(false){ //new_board.game_state == "RM" || new_board.game_state == "RG"){
                return new_board;
            }else if(action == "match"){
                new_board.game_state = "RM";
            }else if(action == "game"){
                new_board.game_state = "RG";
            }else{
                return null;
            }
            return new_board;
        }

        if(! ["RM", "RG", "FF"].includes(old_board.game_state)){
            return null;
        }
        console.log("Handle resign:", old_board);

        var new_board = old_board.copy(); 
        new_board.color = this.player_color;
        new_board.dice = [];

        if(this.player_color == "W"){
            new_board.white_positions = "";
        }else{
            new_board.black_positions = "";
        }

        if((old_board.game_state == "RM" || old_board.game_state == "FF") 
            && old_board.match_length == 0){
            console.log("Resign Match in unlimited", old_board);
            const score_diff = (old_board.cube.owner == "N") ? 1 : Math.abs(new_board.is_won());

            if(this.player_color == "W"){
                new_board.score[0] += score_diff;
            }else{
                new_board.score[1] += score_diff;
            }
            new_board.game_state = "M";
            return new_board;

        }else if(old_board.game_state == "RM" || old_board.game_state == "FF"){
            // The opponent resigned the match
            if(this.player_color == "W"){
                new_board.score[0] = old_board.match_length;
            }else{
                new_board.score[1] = old_board.match_length;
            }
        }

        new_board = this.apply_win(new_board);

        return new_board;
    }

    roll_dice(board){
        board.move_id += 1;
        board.dice = this.roll_dice_callback(board.dice, board.move_id, false);
        board.cube.action = "N";
        board.game_state = "PR";
    }
    
    is_matchpoint(board){
        return ((board.score[0] == board.match_length - 1) || 
                (board.score[1] == board.match_length - 1));
    }

    apply_win(board){
        /* 
         * Takes a board and checks if it is won, if the board won, then apply
         * the correct game state, if the game/match is
         * not won, do nothing;
         *
         */
        // We now have to check if we won the game or the match
        const score = board.is_won();
        const was_matchpoint = this.is_matchpoint(board);
        const match_length = board.match_length;

        if(score > 0){
            if(match_length > 0){
                board.score[0] = Math.min(match_length, board.score[0] + score);
            }else{
                board.score[0] += score;
            }
        }else if(score < 0){
            if(match_length > 0){
                board.score[1] = Math.min(match_length, board.score[1] - score);
            }else{
                board.score[1] -= score;
            }
        }else{
            return board;
        }
        
        // Now check we only won the game or also the match
        const player_score = this.player_color == "W"? board.score[0] : board.score[1];

        if(board.is_lastgame){
            board.game_state = "M";
        }else if(match_length == 0 && this.limit != null && 
            Math.abs(board.score[0] - board.score[1]) >= this.limit){
            console.log("LIMIT REACHED", board, this.limit);
            board.game_state = "M";
        }else if(player_score >= match_length && match_length > 0){
            // we won the match
            board.game_state = "M";
        }else{
            board.game_state = "G"; // we won the game
            // handle crawford
            if(!was_matchpoint && this.is_matchpoint(board)){
                board.is_crawford = true;
            }else{
                board.is_crawford = false;
            }

        }
        return board;
    }

    can_do_checkerplay(board){
        if(board.color == this.player_color){ // It is not the players turn
            return false;
        }

        return (board.game_state == "R" || board.game_state == "IB");
    }

    can_double(board){
        if(board.game_state != "C"){
            return false;
        }
        if(board.color == this.player_color){ // It is not the players turn
            return false;
        }
        
        if(board.is_crawford){
            return false;
        }

        if(board.cube.owner == "D"){ // the cube is dead
            return false;
        }
        // Now check the match score/length
        const player_score = this.player_color == "W" ? board.score[0] : board.score[1];
        const cube_value = 2**(board.cube.value);

        if(board.match_length == 0 && this.limit != null){
            const diff = Math.abs(board.score[0] - board.score[1]);
            if(cube_value*3 + diff > this.limit){
                return false;
            }
        }
        else if(board.match_length > 0 && cube_value + player_score >= board.match_length){
            return false;
        }
        const can_double = (board.cube.owner == "N" || board.cube.owner == this.player_color);
        return can_double;
    }
}
