<template>
<svg width="100%" height="100%" viewBox="0 0 596 595" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(1,0,0,1,-1489.38,-547.453)">
        <g transform="matrix(1,0,0,1,161.336,-163.802)">
            <path d="M1524.9,895.887L1524.9,744.482C1524.9,726.143 1539.79,711.255 1558.13,711.255L1890.77,711.255C1909.11,711.255 1924,726.143 1924,744.482L1924,1077.12C1924,1095.46 1909.11,1110.35 1890.77,1110.35L1737.17,1110.35L1737.17,957.559C1745.06,955.422 1752.52,951.258 1758.71,945.065C1777.62,926.155 1777.62,895.449 1758.71,876.539C1739.8,857.629 1709.1,857.629 1690.19,876.539C1684.57,882.154 1680.62,888.809 1678.34,895.887L1524.9,895.887ZM1869.96,989.076C1851.05,970.165 1820.34,970.165 1801.43,989.076C1782.52,1007.99 1782.52,1038.69 1801.43,1057.6C1820.34,1076.51 1851.05,1076.51 1869.96,1057.6C1888.87,1038.69 1888.87,1007.99 1869.96,989.076ZM1647.47,764.002C1628.56,745.092 1597.85,745.092 1578.94,764.002C1560.03,782.913 1560.03,813.618 1578.94,832.528C1597.85,851.439 1628.56,851.439 1647.47,832.528C1666.38,813.618 1666.38,782.913 1647.47,764.002Z"/>
        </g>
        <g transform="matrix(1,0,0,1,-35.5253,32.0917)">
            <path d="M1924,744.482L1924,1077.12C1924,1095.46 1909.11,1110.35 1890.77,1110.35L1558.13,1110.35C1539.79,1110.35 1524.9,1095.46 1524.9,1077.12L1524.9,744.482C1524.9,726.143 1539.79,711.255 1558.13,711.255L1890.77,711.255C1909.11,711.255 1924,726.143 1924,744.482ZM1631.3,969.03C1604.56,969.03 1582.85,990.742 1582.85,1017.49C1582.85,1044.23 1604.56,1065.94 1631.3,1065.94C1658.05,1065.94 1679.76,1044.23 1679.76,1017.49C1679.76,990.742 1658.05,969.03 1631.3,969.03ZM1817.6,968.527C1790.85,968.527 1769.14,990.239 1769.14,1016.98C1769.14,1043.73 1790.85,1065.44 1817.6,1065.44C1844.34,1065.44 1866.05,1043.73 1866.05,1016.98C1866.05,990.239 1844.34,968.527 1817.6,968.527ZM1817.6,755.664C1790.85,755.664 1769.14,777.376 1769.14,804.119C1769.14,830.863 1790.85,852.575 1817.6,852.575C1844.34,852.575 1866.05,830.863 1866.05,804.119C1866.05,777.376 1844.34,755.664 1817.6,755.664ZM1631.3,756.166C1604.56,756.166 1582.85,777.878 1582.85,804.622C1582.85,831.365 1604.56,853.077 1631.3,853.077C1658.05,853.077 1679.76,831.365 1679.76,804.622C1679.76,777.878 1658.05,756.166 1631.3,756.166Z"/>
        </g>
    </g>
</svg>
</template>
