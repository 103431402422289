<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'


const extra_data = reactive({
    message: "Welcome join or create a match to start playing.",
    user:{},
    open_account_details:false,
    total_nrof_users:0,
    offset:0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const comment_list = reactive([]);
onMounted(() => {
    load_comment_list();
});

async function load_comment_list(){
    var url = app_server + "/admin/puzzle/comment/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    console.log(data);
    comment_list.length = 0;// empty the users list
    if(data.comments){
        comment_list.push(...data.comments);
        extra_data.total_nrof_comments = data.total_nrof_comments;
    }
}

async function delete_comment(comment_id){
    if(!window.confirm(`Are you sure you want to delete comment ${comment_id}`)){
        return;
    }
    const response = await fetch(app_server + `/admin/comment/${ comment_id }/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "revert_solutions": true,
        }),
    });
    const data = await response.json();
    console.log(data);
}

function next_page(){
    extra_data.offset += 100;
    load_comment_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 100);
    load_comment_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
<span>Total comments: {{ extra_data.total_nrof_comments }}</span>
<span>Offset: {{ extra_data.offset }}</span>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">ID</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Author</th> 
        <th scope="col" class="sticky top-0 z-10">Puzzle</th> 
        <th scope="col" class="sticky top-0 z-10">Create Time</th> 
        <th scope="col" class="sticky top-0 z-10">Comment</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr v-for="comment, index in comment_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left text-sm">
            <a href="#" v-if="comment.comment_id">
                {{ comment.comment_id.slice(0,5) }}
            </a>
        </td>
        <td class="text-left">{{ comment.status }}</td>
        <td class="text-left">{{ comment.author_data.username }}</td>
        <td class="text-right font-mono text-sm">
                <router-link :to="{name: 'admin-puzzle-detail', params:{puzzle_id: comment.puzzle_id}}">
                    {{ comment.puzzle_id }}
                </router-link>
        </td>
        <td class="text-right">{{ (new Date(comment.create_time * 1000)).toISOString().slice(2,10) }}</td>
        <td class="text-left text-sm">{{ comment.comment }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>

