<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const match_length_options = [
    1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21,
];

const time_configs = [
    ["Normal (60|10)", "60|10|0"],
    ["Casual (120|30)", "120|30|0"],
    ["Speed (20|8)", "20|8|0"],
    ["Bullet (30|2)", "30|2|0"],
    ["Increment (5|1|3) (debug)", "5|1|3"],
    ["No Clock (debug)", "-1|0|0"],
];

const status_options = [
    "active", "inactive",
]

const extra_data = reactive({
    show_create: false,
    offset: 0,
});

const bot_data = reactive({
    name: "",
    bot_id: "",
    description: "",
    level: "beginner",
    elo: 800,

    match_length: 1,
    timecontrol: "-1|0|0",
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const bot_list = reactive([]);
onMounted(() => {
    load_bot_list();
});

async function load_bot_list(){
    var url = app_server + "/admin/bot/";
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    
    
    const data = await response.json();
    bot_list.length = 0;// empty the users list

    if(data.bots){
        bot_list.push(...data.bots);
    }
    console.log(data);
}

async function create_bot(){
    const response = await fetch(app_server + "/admin/bot/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(bot_data),
    });
    
    const response_data = await response.json();

    extra_data.show_create = false;
    load_bot_list();
}

async function remove_bot(bot_id){
    return;
    const response = await fetch(app_server + `/admin/bot/${bot_id}/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_bot_list();
}

function next_page(){
    extra_data.offset += 20;
    load_bot_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_bot_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

<div class="pl-6" v-if="!extra_data.show_create">
<button class="btn btn-blue" @click="extra_data.show_create = true">
    Create Bot 
</button>
</div>
<div v-if="extra_data.show_create" class="w-96 pl-6">
    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
        Name
    </label>
    <input type="text" v-model="bot_data.name">
    <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
        Bot-id
    </label>
    <input type="text" v-model="bot_data.bot_id">
    <label for="elo" class="block text-sm font-medium leading-6 text-gray-900">
        Elo
    </label>
    <input type="number" v-model="bot_data.elo">
    <label for="description" class="block text-sm font-medium leading-6 text-gray-900">
        Description
    </label>
    <textarea type="text" v-model="bot_data.description"></textarea>
    <label for="level" class="block text-sm font-medium leading-6 text-gray-900">
        Level 
    </label>
    <input type="text" v-model="bot_data.level">
    <label for="length" class="block text-sm font-medium leading-6 text-gray-900">
        Match length
    </label>
    <select v-model="bot_data.match_length" id="length" 
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300" > 
        <option v-for="match_length in match_length_options" :value="match_length">
            {{ match_length }}
        </option>
    </select>
    <label for="timecontrol" class="block text-sm font-medium leading-6 text-gray-900">
        Time Control 
    </label>
    <select v-model="bot_data.timecontrol" id="timecontrol"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
    >
        <option v-for="(state, index) in time_configs"
                :value="state[1]"
        >
            {{ state[0] }}
        </option>
    </select>
    <button class="btn btn-blue mt-2 w-full" @click="create_bot()">
        Create Bot 
    </button>
    <button class="btn btn-red mt-2 w-full" @click="extra_data.show_create=false">
        Cancel
    </button>
</div>

<select v-model="extra_data.status" @change="load_bot_list">
    <option :value="v" v-for="v in status_options"> {{ v }}</option>
</select>

<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">ID</th> 
        <th scope="col" class="sticky top-0 z-10">Name</th> 
        <th scope="col" class="sticky top-0 z-10">Level</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Elo</th> 
        <th scope="col" class="sticky top-0 z-10">Length</th> 
        <th scope="col" class="sticky top-0 z-10">Time</th> 
        <th scope="col" class="sticky top-0 z-10">Elo C.</th> 
        <th scope="col" class="sticky top-0 z-10">Rating C.</th> 
        <th scope="col" class="sticky top-0 z-10">ER</th> 
        <th scope="col" class="sticky top-0 z-10">Wins</th> 
        <th scope="col" class="sticky top-0 z-10">Losses</th> 
        <th scope="col" class="sticky top-0 z-10">Experience</th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="bot_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Bots
    </td>
    </tr>
    <tr v-for="bot, index in bot_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <router-link :to="{name: 'admin-bot-detail', params: {'bot_id': bot.bot_id}}">
                {{ bot.bot_id }}
            </router-link>
        </td>
        <td class="text-left">{{ bot.name }}</td>
        <td class="text-left">{{ bot.level }}</td>
        <td class="text-left">{{ bot.status }}</td>
        <td class="text-right">{{ bot.elo }}</td>
        <td class="text-center">{{ bot.match_length }}</td>
        <td class="text-left">{{ bot.timecontrol }}</td>
        <td class="text-right">{{ bot.elo_computed.toFixed(1) }}</td>
        <td class="text-right">{{ bot.rating_computed.toFixed(1) }}</td>
        <td class="text-right">{{ bot.er.toFixed(1) }}</td>
        <td class="text-right">{{ bot.wins }}</td>
        <td class="text-right">{{ bot.losses }}</td>
        <td class="text-right">{{ bot.experience }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
