<template>
<svg style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2" viewBox="0 0 4785 4164">
  <clipPath id="a">
    <circle cx="2305.9" cy="2017.9" r="1985.8"/>
  </clipPath>
  <g clip-path="url(#a)">
    <path d="M0 0h4784.7v4163.6H0z" style="fill-opacity:0.0"/>
    <path d="M608.4 35.4 1187 4005.8H29.7L608.4 35.4Zm3508.3 0 578.7 3970.4H3538L4116.7 35.4Zm-2338.9 0 578.7 3970.4H1199.1L1777.8 35.4Zm1169.5 0L3526 4005.8H2368.6L2947.3 35.4Z"/>
    <circle cx="1780.4" cy="3370.2" r="586.3" style=""/>
  </g>
  <path d="M2318.2 17.9c1098.9 0 1991 892.1 1991 1991s-892.1 1991-1991 1991-1991-892.1-1991-1991S1219.3 18 2318.2 18Zm0 129.2C1290.6 147 456.3 981.3 456.3 2008.9s834.3 1861.9 1861.9 1861.9c1027.6 0 1861.8-834.3 1861.8-1861.9 0-1027.6-834.2-1861.8-1861.8-1861.8Z"/>
</svg>
</template>
