<script setup>
/*
*/
import {ref, watch, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { HomeIcon, ForwardIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import { StateMachine } from '../assets/js/statemachine.js'
import {BoardState} from '../assets/js/board.js'

import Header from './Header.vue'
import Board from './Board.vue'
import GameSidebar from './GameSidebar.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useDailyStore } from '@/stores/dailystore.js'
const dailyStore = useDailyStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const extra_data = reactive({
    matches: [],
    current_match : null,
    token: null,
});
const chat_data = reactive({});
const clock_data = reactive({});

async function do_move(new_state, action){
    const current_state = new BoardState(dailyStore.current_match.state);
    if(current_state.is_won() || current_state.game_state == "F"){
        router.replace({name:"post", params:{match_id: dailyStore.current_match.match_id}});
    }
    if(typeof action !== 'string'){
        action = null;
    }
    const response = await fetch(app_server + `/daily/${dailyStore.current_match.match_id}/move/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            new_state: new_state,
            action: action,
        }),
    });
    
    const data = await response.json();

    console.log("MOVE:", data);
    if(data.status == "success"){
        dailyStore.current_match.state = data.state;

        if(data.type == "finalize"){
            router.replace({name:"post", params:{match_id: dailyStore.current_match.match_id}});
        }
    }else{
        await dailyStore.send_secret();
    }
}

function handleMove(positionString, action=null){
    const state_machine = new StateMachine();
    state_machine.player_color = dailyStore.current_color;
    state_machine.initial_board = new BoardState(dailyStore.current_match.initial_state);
    state_machine.limit = dailyStore.current_match.limit;

    console.log(positionString, action);

    var next_state = new BoardState(positionString);
    if(typeof action !== 'string'){
        next_state = state_machine.next_state(next_state, action);
    }

    do_move(next_state.toPositionString(), action);
}

function handleAutoroll(){

}

function nextActiveMatch(){
    const active_matches = dailyStore.get_active_matches();

    if(active_matches.length == 0){
        router.push({name: "daily-list"});
    }else{
        dailyStore.current_match_id = active_matches[0].match_id;
        router.replace({
            name: "daily", 
            params: {match_id: dailyStore.current_match_id}
        });
    }
}

onMounted( async () => {
    await dailyStore.get_user_matches();
    dailyStore.current_match_id = route.params.match_id;
    console.log(dailyStore.current_match);

    if(dailyStore.current_state == null){
        console.log("No state, initialize!");
        do_move(null);
    }
    do_move(null);

});
</script>

<template>
<Header class="background" 
    :show_menu="false"
    :show_chat="false"
>
    <div class="flex items-center gap-x-4">
        <router-link :to="{name: 'daily-list'}">
            <button class="btn btn-blue flex items-center">
                <span class="hidden lg:block">
                    Match List
                </span>
                <span class="block lg:hidden">
                    <HomeIcon class="w-6 h-6" />
                </span>
            </button>
        </router-link>
        <button class="btn btn-blue" @click="nextActiveMatch()">
                <span class="hidden lg:block">
                    Next Match
                </span>
                <span class="block lg:hidden">
                    <ForwardIcon class="w-6 h-6" />
                </span>
        </button>
    </div>
</Header>
<div class="w-screen h-rest flex-col relative">
    <div class="game h-rest flex flex-col gap-y-4 pt-4">
        <div v-if="dailyStore.current_match != null"
             class="flex h-full"
        >
            <Board 
               :boardID="'daily_'+dailyStore.current_match.match_id"
               :positionString="dailyStore.current_match.state"
               :player_color="dailyStore.current_color" 
               :clock_data="dailyStore.current_clock"
               :board_message="extra_data.board_message"
               :match_info="dailyStore.current_match"
               :show_chat="false"
               :show_resign="true"
               :show_autoroll="false"
               :direction="dailyStore.current_color == 'B'? 'CCW' : 'CW'"
               @move-end="handleMove"
               @autoroll="handleAutoroll"
            />
            <GameSidebar 
               :player_color="dailyStore.current_color" 
               :match_info="dailyStore.current_match"
               :chat_data="chat_data"
            />
        </div>
    </div>
</div>
</template>

<style scoped>
.game{
    width: 100svw;
    max-width: 100%;
    max-height: 100%;
    background: var(--case-color);
    background: linear-gradient(0.25turn, var(--case-med-color), var(--case-color), var(--case-med-color));
    color: var(--text-color);
    overflow: hidden;
}

.background{
    background: var(--case-color);
    background: linear-gradient(0.25turn, var(--case-med-color), var(--case-color), var(--case-med-color));
    color: var(--board-text-color);
}
</style>

