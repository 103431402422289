<script setup>
import Header from './Header.vue'
import {PuzzlePieceIcon, FireIcon, CpuChipIcon} from '@heroicons/vue/24/solid'

const email = "<span class='font-mono'>admin@domain</span>";
</script>
<template>
<div class="container mx-auto mb-8 px-2 lg:px-0 lg:w-1/2">

<Header />

<h1 class="">Frequently Asked Questions</h1>

<h2>Community</h2>
<p>
    <h3>Where can I find people to play with?</h3>
    You can always try our new <a href="https://discord.gg/cGGYBuww">Discord Server</a>. Another
    great way to meet people to play is to try our Daily tournaments.
</p>


<h2>Beta</h2>
<p>
    <h3>OpenGammon is currently in Beta, what does this mean?</h3>
    This mostly means that we are rapidly developing the platform, which can lead
    to instabilities and or features that are temporarily not working. We will do
    our utmost best to keep all user and match information, so you will not lose progress,
    however it might be that we will have to reset the ratings if we decide to use
    different rating systems.
    <br/><br/>

    If a feature does not work as you expect, or you have some comments/questions, you
    can always send us an email (<span v-html="email" />).
</p>

<p>
    <h3>I found a bug!</h3>
    Send us an email with details of what you were trying to do, what you expected to happen, 
    and what happened instead and we will squash the bug as soon as possible.
</p>

<p>
    <h3>I lost my match because of a bug!</h3>
    We are very sorry to hear this. This should not happen, but this is one of the
    reasons that we are currently in Beta. Send us an email with the details and
    we will squash that bug so it won't happen again.
</p>

<p>
    <h3>Can I help?</h3>
    Yes! First of all, enjoy the platform and try to play some games with friends/family.
    If you want, you can post about OpenGammon on your favorite social media.
    If you are a programmer/designer/etc. and want to help in developing the platform
    you can contact us at <span v-html="email" />. 
</p>

<p>
    <h3>I want to use OpenGammon to stream/Youtube/etc.?</h3>
    You are more than welcome to. We try to cater for everyone and will be more than
    happy to help make using OpenGammon as smooth as possible. For now we allow you
    to draw arrows during play to make explaining moves easier to your audience, we
    are always happy to get ideas.
</p>

<h2>Accounts</h2>
<p>
    <h3>How do I create an account?</h3>
    To create an account you can go <router-link :to="{name:'frontpage'}">here</router-link>
    and click on the register button. You will need to supply a valid email address that
    you have access to since the login tokens will be sent there. After registering
    you need to login.
</p>

<p>
    <h3>How do I Login?</h3>
    To login to your account you can go <router-link :to="{name:'frontpage'}">here</router-link>
    and click on the login button. After filling in your email address we will send you
    a temporary login token that you can use to login to your account.
</p>

<p>
    <h3>How do I change my username/email address/etc.?</h3>
    You can send an email from the email address that is registered with us to
    <span v-html="email" /> with the details.
</p>

<p>
    <h3>How do delete my account?</h3>
    You can send an email from the email address that is registered with us to
    <span v-html="email" /> with the details of your request. To make sure people do not delete
    and recreate accounts we will ban the account.
</p>

<p>
    <h3>I lost access to my email address, can I recover my account?</h3>
    We are sorry, but for most cases we cannot help you. You can always try to
    send us an email in case you have some compelling proof of the account ownership.
</p>

<p>
    <h3>Someone 'stole' my username from another platform?</h3>
    As long as the other account is not impersonating you we cannot do anything.
</p>

<p>
    <h3>I received a ban, what do I do?</h3>
    If the ban is temporary, just wait until the ban expires. We do not give out
    bans easily, so, in all honesty, you probably deserved it. 
</p>

<h2>User Preferences</h2>
<p>
    <h3>I don't like to play (Counter) Clock Wise?</h3>
    We think backgammon players should be able to play both ways, that is
    why in the default setting white plays ClockWise (CW) and black plays Counter ClockWise (CCW).
    However, we asked around and a lot of people have a strong preference for one
    or the other, so we decided to add a user setting for the board direction.
</p>

<p>
    <h3>Can I change the colours?</h3>
    Yes! You can change the colours of the board by clicking on the hamburger menu
    and changing your colour preferences. You can also browse our repository of
    <router-link :to="{name:'color-profiles'}">community board profiles!</router-link>
</p>

<h2>How do I play?</h2>

<p>
    <h3>How do I play backgammon?</h3>
    We encourage you to find someone to teach you the game in person, or read one
    of the many superb books/articles on the topic. If you want a (free) introduction
    to the game you can always send us an email (<span v-html="email" />) and we will be happy to help.
</p>

<p>
    <h3>How do I create a match?</h3>
    After logging in you can join one of the ongoing matches in the match list or
    create a match by clicking the 'Create Match' button.
</p>

<p>
    <h3>How do I play a move?</h3>
    Once it is your turn you can click on the checkers to move them. The game will
    first use the highest dice. If you first want to play the lower number you can
    click on the dice to swap the dice. To conclude the move you can click on the
    dice.
</p>

<p>
    <h3>I want to resign the game?</h3>
    You can resign the game by clicking on the flag button in the lower left corner
    of the board. Take care, because the game resigns to the current state of the game.
    This means that if you did not bear-off any checker, you will lose a gammon (and
    if any of your checkers is still in the opponents home board you will lose a back-gammon).
</p>

<h2 id="section-tournaments">Tournaments</h2>
<p>
    <h3>How do tournaments work?</h3>
    We decided to (for now) implement tournaments in a slightly different fashion
    from what you are probably used to. Tournaments are basically automatic match queues
    that are open for a limited time frame. Each tournament has a set of rules (match length,
    time control, etc.) and each match in the tournament is played with these rules. You
    join the tournament by playing a tournament match, however, you will only be
    ranked after playing a a certain number of matches (log base 2 of the number of participants).
    <br/><br/>
    There are also daily tournaments. These tournaments last for a fixed time (week/month/year) and
    open each day in that period at the same time. You don't have to play every day to
    have a shot at the 1st place.
    <br/><br/>
    After the tournament has finished we compute the ranking of the players in the 
    tournament. There are two tournament ranking formats (1) a (slightly modified)
    <a href="https://en.wikipedia.org/wiki/Bradley–Terry_model">Bradley-Terry</a> ranking scheme, and
    (2) a ladder ranking scheme where you get 1-5 points for a win depending on
    the relative ranking of the players and lose 1 point for a loss.
</p>

<p>
    <h3>What are master points</h3>
    Master points are points you get for participating in tournaments. The amount of
    master points that are awarded in a tournament depends on the number of participants
    and the overall level of the participants. If you end up in the top half of the
    players in the tournament you will be awarded at least some master point. Since
    you cannot lose master points you can feel free to just play one or two matches in
    a tournament if you happen to be around.
</p>

<p>
    <h3>Can I play knockout tournaments?</h3>
    Currently, no. If there is demand for knockout tournaments we can implement them.
    If you want to use OpenGammon to run your own tournament, feel free. 
    Since you can create matches and choose your opponent it is relatively
    easy to set up your own tournament.
</p>

<p>
    <h3>I want to organize a tournament, how can I do it?</h3>
    If you want to organize a specific tournament, you are more than welcome to do it. Send
    us an email and we will create the tournament to your specification. For now it
    is not possible to restrict the tournament to a certain set of users, but if the
    demand is high we can implement that.
</p>

<h2>Analysis</h2>
<p>
    <h3>Does OpenGammon analyse my matches?</h3>
    Yes, we do. We use GnuBG to analyse the matches. The standard analysis is 2-ply
    (which is plenty for most cases), we do plan on adding 3-ply analysis for a small
    fee. After the match is concluded you can see the analysis of the match by clicking
    the 'Analysis' button.
</p>

<p>
    <h3>Can I analyse a single position?</h3>
    Yes, if you click the <CpuChipIcon class="w-6 h-6 inline" /> in the match
    or puzzle analysis, you can analyse the position in-depth.
</p>

<p>
    <h3>I want to analyse my matches with another engine</h3>
    You can analyse your matches by downloading the match file in the analysis screen.
</p>

<h2>Puzzles</h2>
<p>
    <h3>Can I solve puzzles on OpenGammon?</h3>
    Yes! You can solve puzzles <a href="/puzzle/random/">here</a>.
</p>

<p>  
    <h3>How are puzzles generated?</h3>
    We generate puzzles from the blunders/errors that occur in matches as these are probably
    positions that players struggle with. The puzzle's rating will be based on the
    rating of the player making the error. The rating will be updated after other users 
    start solving the puzzle. In the end we hope that the data we collect can be 
    the basis for a model that labels positions in terms of difficulty.
</p>

<p>
    <h3>The puzzle I just solved is incorrect?</h3>
    You can report a puzzle by clicking on the flag.
</p>

<p>
    <h3>I want to submit a puzzle?</h3>
    It is currently not possible to submit positions as puzzles.
</p>

<p>
    <h3>I believe the rating of a puzzle is too high/low?</h3>
    This can happen, but eventually after enough people solve the puzzle the rating
    should converge to its 'real' rating.
</p>

<h2>Miscellaneous</h2>
<p>
    <h3>The dice are rigged!</h3>
    Nope. As I explained to my father after he asked me: "So you can flip a switch 
    that makes me win all games?". No we can't, even if we wanted to we cannot change
    influence or predict the dice of a game. After the game has concluded everyone
    can check the dice and verify that no one cheated. As a side note, if we were able 
    to rig the dice (we still would not do it) we would not be hosting a backgammon
    site but take over the world (as we would have broken SHA-2).
</p>

<p>
    <h3>Player X has cheated!</h3>
    If you think a player has cheated, please use the report button in the post-game
    screen. This way we have all the information that we need to take care of the
    accusations. Please be reminded that we would rather play games than sifting through
    false accusations.
</p>

<p>
    <h3>Player X has harassed me!</h3>
    We do not allow any form of harassment. Please use the report button in the 
    post-match screen and we will take care of it. Do note that we are not aware of all
    the inappropriate language due to language/cultural reasons, so please give
    some explanation (if needed).
</p>

<p>
    <h3>Player X has an inappropriate username!</h3>
    We do not allow any form of harassment. Please use the report button in the post game
    match screen and we will take care of it. Do note that we are not aware of all
    the inappropriate usernames due to language/cultural reasons, so please give
    some explanation (if needed).
</p>
</div>
</template>

<style scoped>
    h1 {
        @apply font-bold text-2xl mt-8 lg:text-4xl;
    }
    h2 {
        @apply font-semibold text-2xl mt-4 mb-2;
    }
    h3 {
        @apply font-semibold text-lg mt-4;
    }
    a {
        @apply text-point-b-color underline;
    }
</style>
