import { defineStore } from 'pinia'
import {useMessageStore } from '@/stores/messagestore.js'

const app_server = import.meta.env.VITE_APP_SERVER;

export const useUserStore = defineStore('user', {
    state: () => { return {
        info: {},
        preferences: {},
        achievements: {},
        defaultPreferences: {},
        online: {},
        authenicated: false,
        last_update: null,
        users_online: [],
        loading_online_users: false,
    };},
    getters: {
    },
    actions:{
        async logout(){
            const messageStore = useMessageStore();

            messageStore.alertUser("Logging out", "You are being logged out.");
            localStorage.removeItem('jwt');
            window.location.reload();
        },

        async fetchUser(){
            const response = await fetch(app_server + "/user/", {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const status_code = response.status;

            if(status_code >= 400){
                if(status_code != 500){ // Don't remove the jwt if it is a server error.
                    // this.logout();
                    // window.location.reload();
                    window.location.href = "/home/";
                }
                return false;
            }
            const user_data = await response.json();

            Object.assign(this.info, user_data.user);

            if(user_data.jwt){
                localStorage.setItem('jwt', user_data.jwt);
            }

            this.authenticated = true;


            return true;

        },
        async loadUser(force_reload=false){
            if(this.last_update == null || Date.now() > this.last_update + 60*60*1000){
                // Reload if the data is older than an hour old
                force_reload = true;
            }

            if(Object.keys(this.info).length > 0 && !force_reload){
                return true; 
            }

            const jwt = localStorage.getItem("jwt");

            if(jwt == null){
                return false;
            }

            if(this.fetch_user_task == null){
                console.log("Reloading user", this.last_update, Date.now());
                this.fetch_user_task = this.fetchUser();
                this.last_update = Date.now();
            }

            await this.fetch_user_task;
            
            return true;
        },
        async loadOnline(force_reload=false){
            while(this.loading_online_users){
                await new Promise(r => setTimeout(r, 500));
            }
            if(this.last_online_update == null || Date.now() > this.last_online_update + 5*60*1000){
                // Reload if the data is older than an hour old
                force_reload = true;
            }
            if(!force_reload && this.users_online.length > 0){
                return true;
            }
            this.loading_online_users = true;

            const response = await fetch(app_server + "/user/online/", {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json()

            if(data.status == "success"){
                this.users_online.length = 0;
                this.users_online.push(...data.online);
                this.users_online.push(this.info.user_id);
            }
            this.last_online_update = Date.now();
            this.loading_online_users = false;
        },

        set(obj, path, value) {
            var schema = obj;  // a moving reference to internal objects within obj
            var pList = path.split('.');
            var len = pList.length;
            for(var i = 0; i < len-1; i++) {
                var elem = pList[i];
                if( !schema[elem] ) schema[elem] = {}
                schema = schema[elem];
            }

            schema[pList[len-1]] = value;
        },
        async updatePreference(key, attribute, value){
            this.set(this.preferences[key], attribute, value);
        },

        async loadPreferences(){
            await this.loadUser();
            if(Object.keys(this.preferences).length > 0 || !this.authenticated){
                return true;
            }
            const response = await fetch(app_server + "/user/preferences/",{
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            Object.assign(this.preferences, data.preferences);
            this.defaultPreferences = data.default_preferences;
        },

        async savePreferences(){
            const response = await fetch(app_server + "/user/preferences/",{
                method: "POST",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify(this.preferences),
            });
            const data = await response.json();
        },

        async resetPreferences(){
            const response = await fetch(app_server + "/user/preferences/",{
                method: "DELETE",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify(this.preferences),
            });
            const data = await response.json();

        },

        applyBoardPreferences(element, colors=null){
            if(element == null){
                return;
            }
            if(colors == null){
                if(this.preferences.board == null){
                    return;
                }
                colors = this.preferences.board.color;
            }
            element.style.setProperty("--field-color", colors.field);
            element.style.setProperty("--case-color", colors.case);
            element.style.setProperty("--board-text-color", colors.board_text || colors.stone_w);
            element.style.setProperty("--highlight-color", colors.highlight);
            element.style.setProperty("--text-color", colors.text || colors.stone_w);

            element.style.setProperty("--button-color", colors.button || colors.stone_w);
            element.style.setProperty("--button-text-color", colors.button_text || colors.stone_b);
            element.style.setProperty("--button-border-color", colors.button_border || colors.case);

            element.style.setProperty("--stone-b-color", colors.stone_b);
            element.style.setProperty("--stone-w-color", colors.stone_w);
            element.style.setProperty("--stone-border-b-color", colors.stone_border_b || colors.case);
            element.style.setProperty("--stone-border-w-color", colors.stone_border_w || colors.case);

            element.style.setProperty("--point-b-color", colors.point_b);
            element.style.setProperty("--point-w-color", colors.point_w);
            element.style.setProperty("--point-b-first-color", colors.point_b_first || colors.point_b);
            element.style.setProperty("--point-w-first-color", colors.point_w_first || colors.point_w);

            element.style.setProperty("--dice-b-color", colors.dice_b);
            element.style.setProperty("--dice-w-color", colors.dice_w);
            element.style.setProperty("--dice-pip-b-color", colors.dice_pip_b);
            element.style.setProperty("--dice-pip-w-color", colors.dice_pip_w);
            element.style.setProperty("--dice-border-b-color", colors.dice_border_b || colors.case);
            element.style.setProperty("--dice-border-w-color", colors.dice_border_w || colors.case);

            element.style.setProperty("--cube-color", colors.cube);
            element.style.setProperty("--cube-pip-color", colors.cube_pip);
            element.style.setProperty("--cube-border-color", colors.cube_border || colors.case);
        },

        async loadAchievements(){
            if(Object.keys(this.achievements).length > 0){
                return true;
            }
            const response = await fetch(app_server + "/user/achievements/",{
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            Object.assign(this.achievements, data.achievements);

            // Compute the border_colors
            const border_colors = {"0": "0"}; //color_keys[achievement.colors[j]]
            for(let achievement of this.achievements.achievements){
                for(let c of achievement.colors){
                    border_colors[c] = achievement.colors.at(-1);
                }
            }
            this.achievements.border_colors = border_colors;
        },

        has_permission(obj, action){
            let has_permission = false;
            console.log("Permissions:", this.info["permissions"]);
            const permission = this.info.permissions[obj];
            if( permission == null){
                has_permission = false;
            }
            else if(typeof permission == "boolean"){
                has_permission = this.info.permissions[obj];
            }
            else if(typeof permission == "object" && Array.isArray(permission)){
                has_permission = permission.includes(action); 
            }

            return has_permission
        },
        is_online(user_id){
            this.loadOnline();
            return this.users_online.includes(user_id);
        },
    }
});
