<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'
import { Match } from '../assets/js/match.js'

import Tooltip from './Tooltip.vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;
const emit = defineEmits([
    'match-joined',
    'cancel',
]);
const props = defineProps(
    ['show', 'tournament_id']
);

var counter_interval_id = null;
var start_time;
var heartbeat_timeout = 60;
var hints = [
    "Did you know you can draw arrows on the board using ctrl-click? You can remove them with the a button.",
    "Did you try Puzzle Rush? You can find it in the menu under Experimental.",
    "You can create your avatar using achievements you earn playing.",
    "Did you know you can conclude your move by hitting the space-bar? This can be a life saver for bullet.",
    "If you have comments or questions you can always send us an email.",
    "You can highlight points on the board by shift clicking them. You can remove the highlight by pressing 'a'.",
    "Ranking high in a tournament grants you Master Points (MP).",
    "By winning a tournament you will earn an achievement.",
];

const extra_data = reactive({
    counter: 0,
    current_hint: 0,
    users_queued: 0,
    cancelled: false,
    joined: false,
    background: true,
});

onMounted(() => {
    sseStore.connect();
    matchStore.setup();
    
    document.addEventListener("visibilitychange", () => {
        extra_data.background = Document.hidden;
        
        if(extra_data.background){
            clearInterval(counter_interval_id);
        }else{
            start_counter();
        }
    });
});

onUnmounted( () => {
    // matchStore.cancel_tournament_join();
});

watch(props, async (newProps, oldProps) => {
});

function start_counter(){
    clearInterval(counter_interval_id);
    counter_interval_id = setInterval(() => {
        extra_data.counter = parseInt((Date.now() - start_time) / 1000);
    }, 1000);
}

function get_random_hint(){
    extra_data.current_hint = Math.floor(Math.random() * hints.length);
}

</script>
<template>
    <div v-if="matchStore.current_queue != null">
        <span class="text-xl font-semibold flex justify-center items-center 
            gap-x-2 p-2 bg-field-color text-stone-w-color">
            <span> You are queueing for: </span>
            <span>{{ matchStore.current_matchqueue_info.name }}</span> 
            <span class="tabular-nums w-16 text-right">{{ matchStore.current_join_counter }}</span>
            <Tooltip :text="'Stop queueing'" :direction="'bottom'">
                <span class="sr-only">Reject</span>
                <XMarkIcon 
                    class="w-6 h-6 cursor-pointer"
                    @click="matchStore.cancel_tournament_join()"
                />
            </Tooltip>
        </span>
    </div>
    <!--
    <Teleport to="body">
    <TransitionRoot as="template" :show="">
    <Dialog as="div" class="relative z-10" @close="">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <h1 class="font-bold text-xl">Looking for a suitable opponent...</h1>
            <h1 class="mx-auto text-4xl font-semibold text-center my-4">{{ extra_data.counter }}</h1>
            <div class="mt-8 mb-4">
                <p class="font-thin">
                    <span class="font-normal mr-2">Hint: </span>{{ hints[extra_data.current_hint] }}
                </p>
            </div>
            <h3 class="text-center">Users queued: {{ extra_data.users_queued }}</h3>
            <button class="btn btn-red mt-2 w-full" @click="cancel_tournament_join()" :disabled="extra_data.cancelled">
                <span v-if="extra_data.cancelled == 'pending'">Cancelling...</span>
                <span v-else>Cancel</span>
            </button>
        
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
    </Teleport>
    -->
</template>

<style scoped>
</style>
