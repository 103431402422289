import {filter_min_direct_shot_actions} from './bot_helper.js'

export class LisaBot{
    name = "Lisa";
    elo = 250;
    match_length = 1;
    description = "Lisa doesn't like to take risks.";

    async find_move(board){
        var action = null;
        if(board.game_state == "R" || board.game_state == "IB"){ // we are after a roll
            const valid_states = Object.values(board.getValidStates());
            if(valid_states.length == 0){
                action = board;
            }else{
                const actions = valid_states.map((x) => x[0][0]);
                const min_shot_actions = filter_min_direct_shot_actions(actions);

                action = min_shot_actions[Math.floor(Math.random() * min_shot_actions.length)];
            }
        } else if(board.game_state == "D"){
            if(Math.random() < 0.60){
                action = "take";
            }else{
                action = "pass";
            }
        } else if(board.game_state == "C"){
            if(Math.random() < 0.10){
                action = "double";
            }else{
                action = "roll";
            }
        }
        return action;
    }

}
