<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {InformationCircleIcon,  XMarkIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import AccountDetails from './AccountDetails.vue'
import JoinTournament from './JoinTournament.vue'
import Header from './Header.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const extra_data = reactive({
    bot_list: {},
    user_score: {},
    user_data: {},
    selected_bot: {},
    show_information: false,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const tournament_id = route.params.tournament_id;

onMounted(() => {
    reload();
    userStore.loadUser();
});


function reload(){
    get_bot_list();
}

async function get_bot_list(){
    var url = app_server + `/bot/`;
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();

    // extra_data.bot_list = data.bots;
    extra_data.bot_list = Object.fromEntries(data.bots);
    const user_data = data.user_data.bot_data;
    for(const level in extra_data.bot_list){
        for(const bot of extra_data.bot_list[level]){
            if(Object.keys(extra_data.selected_bot) == 0){
                extra_data.selected_bot = bot;
            }
            bot.icon_url = new URL(`/src/assets/images/bot_avatars/${bot.bot_id}.svg`, import.meta.url);
            if(user_data != null && bot.bot_id in user_data){
                bot.user_stats = user_data[bot.bot_id];
                bot.stars = user_data[bot.bot_id].stars;
            }else{
                bot.user_stats = {};
                bot.stars = 0;
            }
        }
    }
    
    console.log(data);
}

function select_bot(bot){
    extra_data.selected_bot = bot;
    extra_data.show_information = false;
}

</script>

<template>
    <Header :user="extra_data.user">
    </Header>
     
    <div class="container mx-auto max-w-xl">
        <div class="mx-auto w-96 bg-case-med-color  text-stone-w-color flex flex-col items-center gap-y-4 p-4 rounded-lg relative"
            v-if="Object.keys(extra_data.selected_bot).length > 0"
        >
            <h1 class="text-4xl font-bold">Play vs...</h1>
            <div class="flex flex-col items-center gap-y-4">
                <div class="w-36 h-36 overflow-hidden">
                    <img class="object-contain" :src="extra_data.selected_bot.icon_url">
                </div>
                <p class="text-xl font-semibold">
                    {{ extra_data.selected_bot.name}} <span class="font-thin">({{ extra_data.selected_bot.elo }})</span></p>
            </div>
            <p v-if="extra_data.show_information "
                class="text-center min-h-[5em] flex justify-center">
                <table class="w-full table-fixed">
                    <tr class="text-lg font-semibold overflow-hidden">
                        <td class="w-1/3 whitespace-nowrap truncate">
                            {{ userStore.info.username }}
                        </td>
                        <td class="w-1/3 text-center"></td>
                        <td class="w-1/3 whitespace-nowrap truncate">
                            {{ extra_data.selected_bot.name }}
                        </td>
                    </tr>
                    <tr v-if="extra_data.selected_bot.user_stats.wins != null">
                        <td class="w-1/3">
                            {{ extra_data.selected_bot.user_stats.wins }}
                        </td>
                        <td class="w-1/3 text-center">Wins</td>
                        <td class="w-1/3">
                            {{ extra_data.selected_bot.user_stats.losses }}
                        </td>
                    </tr>
                    <tr>
                        <td class="w-1/3">
                        </td>
                        <td class="w-1/3 text-center">Win %</td>
                        <td class="w-1/3">
                            {{ (100*extra_data.selected_bot.wins / (extra_data.selected_bot.losses + extra_data.selected_bot.wins)).toFixed(1) }}%
                        </td>
                    </tr>
                    <tr v-if="extra_data.selected_bot.user_stats.wins != null">
                        <td class="w-1/3">
                            {{ extra_data.selected_bot.user_stats.ER_wins }}
                        </td>
                        <td class="w-1/3 text-center">ER wins</td>
                        <td class="w-1/3">
                            {{ extra_data.selected_bot.user_stats.ER_losses }}
                        </td>
                    </tr>
                    <tr v-if="extra_data.selected_bot.user_stats.wins != null">
                        <td class="">{{ extra_data.selected_bot.user_stats.avg_player_ER.toFixed(2) }}</td>
                        <td class="text-center">ER</td>
                        <td class="">{{ extra_data.selected_bot.user_stats.avg_bot_ER.toFixed(2) }}</td>
                    </tr>
                    <tr>
                        <td class="">{{ userStore.info.rating.toFixed(2) }}</td>
                        <td class="text-center">Rating</td>
                        <td class="">{{ extra_data.selected_bot.rating_computed.toFixed(2) }}</td>
                    </tr>
                </table>
            </p>
            <p v-else class="text-center min-h-[5em]">
                {{ extra_data.selected_bot.description }}
            </p>
            <router-link :to="{name: 'bot-match', params:{'bot_id': extra_data.selected_bot.bot_id}}"
                class="w-full flex justify-center"
            >
                <button class="btn btn-blue h-12 w-1/3 text-2xl">
                    Play
                </button>
            </router-link>
            <InformationCircleIcon 
                @click="extra_data.show_information = ! extra_data.show_information"
                class="absolute w-6 h-6 top-2 right-2 opacity-40 hover:opacity-100 cursor-pointer" 
            /> 
        </div>
        <div class=""
            v-for="level in Object.keys(extra_data.bot_list)"
        >
            <span class="mt-4 mb-2 text-2xl inline-block capitalize">
                {{ level }}
            </span>
            <div class="flex gap-x-2 px-2">
                <div class="w-24 h-24 bg-case-light-color shadow-lg p-2 rounded-lg ring-offset-2 ring-field-color overflow-hidden relative"
                     :class="{ring: bot.bot_id == extra_data.selected_bot.bot_id}"
                    v-for="bot in extra_data.bot_list[level]"
                    @click="select_bot(bot)"
                >
                    <img class="object-contain" :src="bot.icon_url">
                    <span
                        class="absolute inset-x-2 bottom-0 flex justify-center">
                    <svg v-for="i in Array(bot.stars).keys()"
                         class="w-4 h-4 text-yellow-300 stroke-case-color stroke-2 ms-1" 
                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                         fill="currentColor" viewBox="0 0 22 20">
                         <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                    </svg>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>
