<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var bot_id;

const extra_data = reactive({
    bot: {}
});


onMounted(() => {
    bot_id = useRoute().params.bot_id;
    load_bot();
});

async function save_bot(){
    var url = app_server + `/admin/bot/${bot_id}/`;
    const data = extra_data.bot;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const bot_data = await response.json();
    if(bot_data.bot){
        extra_data.bot = bot_data.bot;
    }
    console.log(bot_data);
}

async function load_bot(){
    var url = app_server + `/admin/bot/${bot_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log(data);
    if(data.bot){
        extra_data.bot = data.bot;
    }
}

</script>

<template>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-bots'}">
            Bot List
        </router-link>
    </button>
    <h1>Tournament Detail</h1>
    <button @click="save_bot()" class="btn btn-blue">Save</button>
    <form>
        <div v-for="value,field in extra_data.bot">
            <label :for="field"
                   class="block text-sm font-medium leading-6 text-gray-900"
            >
                {{ field }}
            </label>
            
            <textarea 
                v-model="extra_data.bot[field]"
                v-if="field == 'description'"
                :id="field"
            ></textarea>
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="extra_data.bot[field]"
                v-else
                :id="field"
            />
        </div>
    </form>
</template>

<style>
</style>
