<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import ButtonLoad from "./ButtonLoad.vue"

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import { debounce } from '../assets/js/debounce.js'

const app_server = import.meta.env.VITE_APP_SERVER;

const extra_data = reactive({
    show: false,
    state: "idle",
    errormessage: "",
});

const data = reactive({
    email: "",
    username: "",
    tos: false,
});

const emit = defineEmits([
    'registered',
]);

onMounted(() => {

});

function sleep(n) { return new Promise(resolve=>setTimeout(resolve,n)); }

function cancel(){
    extra_data.show=false;
    extra_data.state = "idle";
    data.email = "";
    data.username = "";
}

async function register(){
    console.log("REGISTERING");
    if(!extra_data.show){
        extra_data.show = true;
        return;
    }
    extra_data.state = "registering"

    const response = await fetch(app_server + "/user/register/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
        },
        body:JSON.stringify(data),
    });

    await sleep(2000); 

    const register_data = await response.json();
    if(register_data["status"] == "success"){
        extra_data.show = false;
        emit("registered");
        messageStore.alertUser("Registration Successful", "Please continue by logging in.");
        extra_data.state = "registered";
    }else if(register_data["status"] == "error"){
        console.log("ERROR", register_data);
        messageStore.alertUser("Registration Failed", register_data.message);
        extra_data.errormessage = register_data["message"];
        extra_data.state = "idle";
    }
}

function can_register(){
    return data.email.length > 0 && data.username.length > 0 && data.tos;
}

</script>
<template>
<button 
    class="btn btn-blue md:text-2xl md:w-1/3" 
    @click="extra_data.show=true"
>
    Register
</button>

<Teleport to="body">
<TransitionRoot as="template" :show="extra_data.show">
    <Dialog as="div" class="relative z-10" @close="cancel()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-top justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div class="flex flex-col gap-y-4">
                <h2 class="text-2xl">
                    Create an account
                </h2>
                <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
                    Email
                </label>
                <input type="email" v-model="data.email" 
                       id="email"
                       class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 mb-2"
                       placeholder="email@example.com"/>
                </div>

                <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
                    Username 
                </label>
                <input type="text" v-model="data.username" 
                       class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 mb-2"
                       placeholder="Username"/>
                </div>

                <div>
                    <input type="checkbox" v-model="data.tos" id="tos" class="mr-2"/> 
                    <label for="tos">I agree with the <a href="/static/tos/" class="underline pointer">ToS</a> and 
                            <a href="/privacy/">Privacy Policy</a>
                    </label>
                    <p class="mt-2 text-sm text-warning-color" id="error">
                        {{ extra_data.errormessage }}
                    </p>
                </div>

                <ButtonLoad 
                    :loading="extra_data.state == 'registering'"
                    :is_disabled="!can_register()"
                    class="btn btn-blue w-full h-12 text-xl"
                    @click="register()"
                >
                    Register
                </ButtonLoad>
                </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</Teleport>
</template>

<style scoped>
form{
    display:inline-block;
}
</style>
