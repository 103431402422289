import {BoardState} from './board.js'
import {count_direct_shots} from './bots/bot_helper.js'

export class Engine{
    /*
     * A simple engine that takes a couple of arbitrary metrics and computes
     * a score for a position. it Uses this score to rank the position (and the move)
     */

    player = "B";
    opponent = (this.player == "W") ? "B" : "W";
    
    metric_weights = [
        [(x) => this.pip_count(x), 0.2],
        [(x) => this.born_off(x), 15],
        [(x) => this.bar(x), 40],
        [(x) => this.direct_shots(x, this.player) , 15],
        [(x) => this.direct_shots(x ,this.opponent) , -40],
        [(x) => this.count_blots(x, this.player) , -50],
        [(x) => this.count_blots(x, this.opponent) , 20],
        [(x) => this.homeboard(x, this.player) , 200],
        [(x) => this.homeboard(x, this.opponent) , -20],
        [(x) => this.on_bar(x, this.opponent)**1.2*this.homeboard(x, this.player)**2, 40],
        // player_prime : 1,
        // opponent_prime : 1,
        // player_homeboard : 1,
        // o pponent_homeboard : 1,
    ];

    possible_dice = [ 
        [1,1], [2,2], [3,3], [4,4], [5,5], [6,6],
        [1,2], [1,3], [1,4], [1,5], [1,6],
        [2,3], [2,4], [2,5], [2,6],
        [3,4], [3,5], [3,6],
        [4,5], [4,6],
        [5,6],
    ];
    
    pip_count(board){
        const pip = board.get_pipcount()

        return pip[this.player] - pip[this.opponent];
    }
    
    born_off(board){
        const pip = board.get_nrof_born_off()

        return pip[this.player] - pip[this.opponent];
    }

    on_bar(board, color){
        const player_bar = board.get_point_index(board.bar_points[color]);
        return board.points[player_bar].nrof_stones;

    }

    bar(board){
        return this.on_bar(board, this.opponent) - this.on_bar(board, this.player);
    }

    direct_shots(board, color){
        return count_direct_shots(board, color);
    }

    homeboard(board, color){
        let home_board_points = [1,2,3,4,6,5];
        if(color == "W"){
            home_board_points = home_board_points.map(x => 25 - x);
        }
        let nrof_homeboard_points = 0;
        let score = 1;
        for(let point_id of home_board_points){
            const point = board.points[board.get_point_index(point_id)];
            if(point.color == color && point.nrof_stones >= 2){
                nrof_homeboard_points += score;
            }
            score += 0.5;
        }
        return nrof_homeboard_points;
    }

    count_blots(board, color){
        let nrof_blots = 0;
        for(let point of board.points){
            if(point.color == color && point.nrof_stones == 1){
                nrof_blots += 1;
            }
        }
        return nrof_blots;
    }

    eval_board(board){
        let score = 0;
        for(let [metric, weight] of this.metric_weights){
            score += metric(board) * weight;
        }
        if(this.player == board.color){
            return score;
        }else{
            return -score;
        }
    }

    eval_recursive(board, ply){
        if(ply <= 0){
            return this.eval_board(board);
        }

        let score = 0;
        for(let dice of this.possible_dice){
            const dice_prob = (dice[0] == dice[1]) ? 1/36 : 1/18;
            const valid_states = board.getValidStates(dice);
            const scores = [];
            
            for(let state in valid_states){
                const new_state = valid_states[state][0][0];
                new_state.color = new_state.opponent[new_state.color];
                scores.push(
                    this.eval_recursive(new_state, ply-1)
                );
            }
            if(board.color == this.player){
                score += dice_prob * Math.max(...scores);
            }else{
                score += dice_prob * Math.min(...scores);
            }
        }
        return score;
    }
    
    eval(root_board, ply=1){
        return this.eval_recursive(root_board, ply);

        const board_stack = [[root_board, 1, ply]];
        let score = 0;

        while(board_stack.length > 0){
            const [board, probability, board_ply] = board_stack.pop();

            if(board_ply <= 0){ // BASE CASE
                score += this.eval_board(board) * probability;
                continue;
            }
            const board_score = 0;
            for(let dice of this.possible_dice){
                const dice_prob = (dice[0] == dice[1]) ? 1/36 : 1/18;
                const valid_states = board.getValidStates(dice);
                const dice_score = null;

                for(let state in valid_states){
                    const new_state = valid_states[state][0][0];
                    new_state.color = new_state.opponent[new_state.color];
                    const sc = eval(new_state, board_ply - 1);
                }
            }
        }
        return score;
    }

    get_move(board, ply){
        const moves = {};
        const valid_states = board.getValidStates();
        for(let state in valid_states){
            if(moves[state]){
                continue;
            }
            const bs = valid_states[state][0][0];
            const move = valid_states[state][0][2];
            moves[bs.toPositionString()] = [this.eval(bs, ply), move];
        }
        const ordered_moves = Object.entries(moves).sort( (a,b) => b[1][0] - a[1][0]);
        for(let o_move of ordered_moves.slice(0,4)){
            const score = o_move[1][0];
            const move = o_move[1][1];
            console.log(move.map(x => x.join("->")).join(" "), score.toFixed(3));
        }
        console.log(ordered_moves);

        return ordered_moves;
    }
}
