<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const report_list = reactive([]);

const object_type_to_name = {
    "puzzle": "puzzle",
    "match": "post",
};

const extra_data = reactive({
    offset: 0,
});

onMounted(() => {
    load_report_list();
});

function get_link_to(object_type, object_id){
    if(object_type == "puzzle"){
        return {
            name: "puzzle", 
            params: {puzzle_id: object_id}
        };
    }else if(object_type == "match"){
        return {
            name: "match", 
            params: {match_id: object_id}
        };
    }else{
        return {};
    }
}

async function load_report_list(){
    var url = app_server + "/admin/report/";
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    
    
    const data = await response.json();
    report_list.length = 0;// empty the users list

    if(data.reports){
        report_list.push(...data.reports);
    }
    console.log(data);
}

async function create_report(){
    const response = await fetch(app_server + "/admin/report/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(report_data),
    });
    
    const response_data = await response.json();

    load_report_list();
}

async function change_status(report_id, status="closed"){
    const response = await fetch(app_server + `/admin/report/${report_id}/changestatus/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            status: status,
        }),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_report_list();
}

function next_page(){
    extra_data.offset += 20;
    load_report_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_report_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Submitter</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Object</th> 
        <th scope="col" class="sticky top-0 z-10">Reason</th> 
        <th scope="col" class="sticky top-0 z-10">Description</th> 
        <th scope="col" class="sticky top-0 z-10">Time submitted</th> 
        <th />
    </tr>
</thead>
<tbody>
    <tr  v-if="report_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Bots
    </td>
    </tr>
    <tr v-for="report, index in report_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-center">{{ report.user_data.username }}</td>
        <td class="text-center">{{ report.status }}</td>
        <td class="text-center">
            <router-link
                :to="get_link_to(report.object_type, report.object_id)">
            {{ report.object_type }}
            </router-link>
        </td>
        <td class="text-center">{{ report.reason }}</td>
        <td class="text-center truncate">{{ report.description }}</td>
        <td class="text-center">
            {{ new Date(report.submit_time * 1000).toISOString().split(".")[0] }}
        </td>
        <td class="text-center">
            <button class="btn btn-red" 
                    @click="change_status(report.report_id, 'closed')"
            >
                Close
            </button>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
