<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Match } from '../assets/js/match.js'

const app_server = import.meta.env.VITE_APP_SERVER;
const emit = defineEmits([
    'hide',
]);

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  latency_w: {
    type: Object,
    default: {},
  },
  latency_b: {
    type: Object,
    default: {},
  },
  server: {
    type: Object,
    default: {},
  },
  last_update: {
    type: String,
    default: "",
  },
});

onMounted(() =>{ 
});

onUnmounted(() =>{ 
});

</script>
<template>
    <div class="hidden md:flex absolute top-0 left-0 text-point-w-color 
                font-thin text-sm
                gap-x-4 px-2 opacity-40 z-20 hover:opacity-100"
        v-if="latency_w != null && latency_b != null"
    >
        <div class="flex items-center gap-x-1 z-20">
            <span class="bg-stone-w-color w-3 h-3 rounded-full inline-block" />
            <span>{{ (latency_w.median*1000).toFixed(0) }}ms</span>
        </div>
        <div class="flex items-center gap-x-1 z-20">
            <span class="bg-stone-b-color w-3 h-3 rounded-full inline-block" />
            <span>{{ (latency_b.median*1000).toFixed(0) }}ms</span>
        </div>
        <div class="flex items-center gap-x-1 z-20">
            <span>({{ (server.median*1000).toFixed(0) }}ms)</span>
        </div>
        <div class="absolute inset-0 bg-case-color opacity-80" />
    </div>
</template>

<style scoped>
</style>
