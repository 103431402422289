<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const extra_data = reactive({
    offset: 0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const status_options = [];

const blog_list = reactive([]);
onMounted(() => {
    load_blog_list();
});

async function load_blog_list(){
    var url = app_server + "/admin/blog/";
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    
    
    const data = await response.json();
    blog_list.length = 0;// empty the users list

    if(data.posts){
        blog_list.push(...data.posts);
    }
    console.log(data);
}

async function remove_blog(blog_id){
    return;
    const response = await fetch(app_server + `/admin/blog/${blog_id}/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_blog_list();
}

function next_page(){
    extra_data.offset += 20;
    load_blog_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_blog_list();
}

</script>

<template>
<div class="flex-col">
<div class="flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

<div class="pl-6" v-if="!extra_data.show_create">
    <div class="my-4">
        <router-link :to="{name:'admin-blog-detail', params:{slug: 'new'}}">
        <button class="btn btn-blue" @click="clear_blog_queue">+ New Post</button>
        </router-link>
    </div>


<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <!--<th scope="col" class="sticky top-0 z-10">ID</th> -->
        <th scope="col" class="sticky top-0 z-10">Slug</th> 
        <th scope="col" class="sticky top-0 z-10">Author</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <!-- <th scope="col" class="sticky top-0 z-10">Path</th>  -->
        <th scope="col" class="sticky top-0 z-10">Create Time</th> 
        <th scope="col" class="sticky top-0 z-10">Views</th> 
        <th scope="col" class="sticky top-0 z-10">Title</th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="blog_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Blog posts
    </td>
    </tr>
    <tr v-for="blog, index in blog_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <router-link :to="{name: 'admin-blog-detail', params:{slug: blog.slug}}">
            {{ blog.slug }}
            </router-link>
        </td>
        <td class="text-left">{{ blog.author.slice(0, 8) }}</td>
        <td class="text-left">{{ blog.status }}</td>
        <!-- <td class="text-left">{{ blog.path }}</td> -->
        <td class="text-left">{{ new Date(blog.create_time*1000).toISOString().split(".")[0] }}</td>
        <td class="text-right">{{ blog.views }}</td>
        <td class="text-left">{{ blog.title }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
