<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const app_server = import.meta.env.VITE_APP_SERVER;


const props = defineProps({
    state: {
        type: String,
        default: "",
    },
    match_id: {
        type: String,
        default: "",
    }
});

const data = reactive({
    explanation: "",
    tags: "",
    puzzle_type: "checker",
});

const emit = defineEmits([
    'puzzle-created',
    'cancel',
]);

onMounted(() => {

});

async function create_puzzle(){
    var puzzle_data = {
        state: props.state,
        explanation: data.explanation,
        tags: data.tags,
        match_id: data.match_id,
        puzzle_type: data.puzzle_type,
    };

    console.log(puzzle_data);
    const response = await fetch(app_server + "/puzzle/create/", {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(puzzle_data),
    });
    
    puzzle_data = await response.json();
    console.log(puzzle_data)
    emit("puzzle-created");
}

</script>
<template>
    <Teleport to="body">
    <TransitionRoot as="template" :show="props.state.length > 0">
    <Dialog as="div" class="relative z-10" @close="emit('cancel')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">

        <label for="explanation" class="block text-sm font-medium leading-6 text-gray-900">
            Explanation
        </label>
        <textarea class="w-full rounded-md border-0" id="explanation" v-model="data.explanation"
                placeholder="An explanation of the puzzle (e.g. why the best answer is correct). You can leave this blank if you don't know."        
        ></textarea>
        <label for="tags" class="block text-sm font-medium leading-6 text-gray-900">
            Tags
        </label>
        <textarea class="w-full rounded-md border-0" id="explanation" v-model="data.tags"
                placeholder="Comma seperated list of tags (e.g. opening theory, ."        
        ></textarea>
        <label class="block text-sm font-medium leading-6 text-gray-900">
            Puzzle Type 
        </label>
        <input type="radio" value="checker" id="radio_checker" v-model="data.puzzle_type"/>
            <label for="radio_checker">Checker</label>
        <input type="radio" value="double" id="radio_double" v-model="data.puzzle_type"/>
            <label for="radio_checker">Double</label>
        
        <button class="btn btn-blue mt-2 w-full" @click="create_puzzle()">
            Create Puzzle
        </button>
        <button class="btn btn-red mt-2 w-full" @click="emit('cancel')">
            Cancel
        </button>
        
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
    </Teleport>
</template>

<style scoped>
</style>
