
<script setup>
const props = defineProps({
    choices: {
        type: Array,
    },
    show: {
        type: Boolean,
        default: false,
    },
    position: {
        type: String,
        default: "left",
    }
})

const emit = defineEmits([
    'prompt-answer'
]);

</script>

<template>
    <div class="prompt gap-x-4 flex h-8 md:h-12" :class="[position]" v-if="show">
        <button class="btn button min-w-fit w-20 text-sm md:text-lg md:w-24" v-for="value in choices"
            @click="emit('prompt-answer', value)"
        >
            {{ value }}
        </button>
    </div>
</template>

<style scoped>
    .prompt{
        position:absolute;
        top:50%;
        z-index: 1000;
    }
    .left{
        left:25%;
        transform: translate(-50%, -50%);
    }
    .right{
        right:25%;
        transform: translate(50%, -50%);
    }
    .both{
        left:12.5%;
        right:12.5%;
        transform: translate(0, -50%);
        justify-content: space-between;
    }
    .button{
        background-color: var(--button-color);
        color: var(--button-text-color);
        border: 2px solid var(--button-border-color);
    }
</style>
