<script setup>
const props = defineProps({
    owner: {
        type: String,
    },
    value:{
        type: Number,
    },
    action:{
        type: String,
    },
    player_color: {
        type: String,
    },
    color_playing: {
        type:String,
    }
});

function get_cube_value(){
    if(props.value == -1){
        // We use -1 to signify crawford 
        return "Cr";
    }
    if(props.action == "O" ){
        return 2**(props.value+1);
    }else{
        return 2**(props.value);
    }
}

function get_owner(){
    if(props.owner == "N" || props.owner == "?"){
        return null;
    }
    return props.owner;
}

function get_position(){
    if(props.owner == "N" || props.owner == "?"){
        return "justify-center";
    }else{
        if(props.player_color == props.owner){
            return "justify-end";
        }else{
            return "justify-start";
        }
    }    
}

function get_cube_position(){
    if(props.action == "O" ){
        if(props.color_playing == props.player_color){
            return "offer";
        }else{
            return "offered"
        }
    }
}

</script>

<template>
    <div class="flex flex-col h-full px-1" 
        :class="[get_position()]">
        <div class="cube aspect-square text-md w-full
                    @md:text-2xl @xl:text-5xl flex justify-center items-center"
             :class="[get_cube_position()]">
            <span>{{ get_cube_value() }}</span>
        </div>
    </div>
</template>

<style scoped>
.cube{
    background:var(--cube-color);
    color: var(--cube-pip-color);
    text-align:center;
    border: 0.25rem solid;
    border-radius: 0.2em;
    border-color: var(--cube-border-color);
}
.offer{
    position:absolute;
    width: 1.5em;
    right: 75%;
    top:50%;
    transform: translate(-0%, -150%);
}
.offered{
    position:absolute;
    width: 1.5em;
    right:25%;
    top:50%;
    transform: translate(-50%, -50%);
}
    
.top{
    justify-content: flex-end;
}   
.bottom{
    justify-content: flex-start;
} 
.player{
    
}
        
</style>
